import React from 'react';
import { isEmpty, mapKeys } from "lodash";
import BusinessAdapter from "services/businesses/adapter";
import { Pane, Loading } from 'shared/components/layouts';
import { withRouter, Link } from 'react-router-dom';
import { formatRoute } from "shared/helpers";
import { PriceItem } from 'shared/components';
import { Rating } from 'semantic-ui-react';
import Config from "constants/Config";
import moment from "moment";
import { injectIntl, intlShape } from 'react-intl';

class N_SearchBox extends React.Component {
	static propTypes = {
		intl: intlShape.isRequired,
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			keyword: '',
			typing: false,
			typingTimeout: 0,
			results: [],
			loadingQuick: false,
			type: this.props.area.type,
		};
		this.onChangeKeyword = this.onChangeKeyword.bind(this);
	}

	onChangeKeyword = (event) => {
		if (!event.target.value) {
			this.setState({ results: [], keyword: event.target.value });
			return 0;
		}

		const self = this;

		if (self.state.typingTimeout) {
			clearTimeout(self.state.typingTimeout);
		}

		self.setState({
			keyword: event.target.value,
			typing: false,
			typingTimeout: setTimeout(function () {
				self.searchQuick(self.state.keyword);
			}, 800)
		});
	}

	searchQuick = (keyword) => {
		this.setState({ loadingQuick: true });
		const params = {
			keyword: keyword,
			state: this.props.area.user_area.state,
			city: this.props.area.user_area.city,
			type: this.state.type
		};
		this.props
			.searchQuick(params)
			.then((response) => {
				this.setState({ loadingQuick: false });
				let results = BusinessAdapter.quick.parseResponse(response.data).search;
				this.setState({ results });
			})
			.catch(() => {
				alert('Some err...');
				this.setState({ loadingQuick: false });
			});
	}

	redirectQuick = (id, type) => {
		let url = '';
		switch (type) {
			case 'Food':
				url = `/${id}/menu`;
				break;
			case 'Product':
				url = `/${id}/products`;
				break;
			case 'Service':
				url = `/${id}/services`;
				break;
			case 'User':
				url = `/users/${id}`;
				break;
			default:
				url = `/${id}/menu`;
		}
		this.props.history.push(url);
		setTimeout(() => {
			this.toggleSearch();
		}, 500);
	};

	toggleSearch = () => {
		this.setState({ results: [], keyword: '' });
		this.props.toggleSearch();
	}

	renderStatus = (tradingHours) => {
		if (!isEmpty(tradingHours)) {
			const currentDate = moment().format("ddd");
			const renamed = mapKeys(JSON.parse(tradingHours), (value, key) => key.toLowerCase());
			const tradingHoursCurrent = renamed[currentDate.toLowerCase()];
			if (!isEmpty(tradingHoursCurrent)) {
				const fromDate = moment(tradingHoursCurrent.from, "HH:mm");
				const toDate = moment(tradingHoursCurrent.to, "HH:mm");
				if (moment().isBetween(fromDate, toDate) && !tradingHoursCurrent.off) {
					return <div className="box-active" />;
				}
				return <div className="box-inactive" />;
			}
			return <div className="box-inactive" />;
		}
		return <div className="box-inactive" />;
	};

	renderTitleDropdown = () => {
		const { intl } = this.props;
		if (this.state.type === 'Food') return intl.formatMessage({ id: 'food' });
		if (this.state.type === 'Product')
			return intl.formatMessage({ id: 'products' });
		if (this.state.type === 'Service')
			return intl.formatMessage({ id: 'services' });
		if (this.state.type === 'User')
			return intl.formatMessage({ id: 'users' });
		return null;
	};
	onChangeDropdown = (value) => {
		this.setState(
			{
				type: value,
			},
			() => {
				document.getElementById('search-dropdown').checked = false;
				this.searchQuick(this.state.keyword);
			}
		);
	};


	render() {
		const { intl } = this.props;
		const options = [
			{
				key: 1,
				value: 'Food',
				text: intl.formatMessage({ id: 'food' }),
			},
			{
				key: 2,
				value: 'Product',
				text: intl.formatMessage({ id: 'products' }),
			},
			{
				key: 3,
				value: 'Service',
				text: intl.formatMessage({ id: 'services' }),
			},
			{
				key: 4,
				value: 'User',
				text: intl.formatMessage({ id: 'users' }),
			},
		];
		return (
			<Pane id="myOverlay" className={`overlay ${this.props.showSearch ? 'd-block' : 'd-none'}`}>
				<span className="closebtn" onClick={this.toggleSearch} title="Close Overlay">×</span>
				<Pane className="overlay-content">
					<Pane className="search-title">
						I'm looking for...
					</Pane>
					<Pane className="block-search">
						<Pane onClick={this.toggleSearch} className="search-icon">❮</Pane>
						<Pane className="search-field">
							<img
								src={process.env.PUBLIC_URL + '/assets/images/layouts/search.svg'}
								alt="store"
								className="icon-search"
							/>
							<input
								type="text"
								className={`${!this.state.loadingQuick && this.state.results.length > 0 ? 'exists' : ''}`}
								placeholder="Search.."
								value={this.state.keyword}
								autoComplete="off"
								onChange={this.onChangeKeyword}
								autoFocus
							/>
						</Pane>
						<Pane className={`search-dropdown ${!this.state.loadingQuick && this.state.results.length > 0 ? 'drop-exists' : ''}`}>
							<input
								className="search-checkbox"
								type="checkbox"
								id={this.props.id}
							/>
							<label className="default-option" htmlFor={this.props.id}>
								{this.renderTitleDropdown()}
								<img
									src={`${process.env.PUBLIC_URL
										}/assets/images/layouts/arrow-1.svg`}
									alt="store"
									className="icon-default"
								/>
							</label>
							<ul className="list-dropdown">
								{options.map((item) => {
									return (
										<li
											key={item.key}
											onClick={() => this.onChangeDropdown(item.value)}
											className="item-dropdown"
										>
											{item.text}
										</li>
									);
								})}
							</ul>
						</Pane>
					</Pane>
					{!this.state.loadingQuick && this.state.results.length > 0 && (
						<ul className="search-list">
							{this.state.results.length > 0 ? (
								this.state.results.map((item, index) => {
									let items = (item.type) !== Config.BUSINESS_TYPE.SERVICE ? item.products : item.services;
									return (
										<li key={index} className="search-item">
											<Pane onClick={() => this.redirectQuick(item.type == 'User' ? item.id : item.userId, item.type)} className="search-business">
												<Pane className="search-business__image">
													{this.renderStatus(item.tradingHours)}
													<img src={`${item.url ? item.url : `${process.env.PUBLIC_URL}/assets/images/user.jpg`}`} alt={item.name} />
												</Pane>
												<Pane className="search-business__info">
													<Pane className="search-business__info-name">{item.name}</Pane>
													<Pane className="search-business__info-addr">{item.address}</Pane>
													<ul className="list-star">
														<Rating
															disabled
															icon='star'
															rating={item.rating || 0}
															maxRating={1}
															className="mr-1"
															size='tiny'
														/>
														<span>{item.rating || 'No Rating'}</span>
													</ul>
												</Pane>
											</Pane>
											<Pane className="search-products">
												<Pane className="sub-items mt-2">
													{items && items.length > 0 && items.map((obj) => {
														return <Pane className="sub-item" key={obj.id}>
															<Link
																to={obj.type === Config.BUSINESS_TYPE.SERVICE ? `/services/${`${formatRoute(obj.name, '-')}-${obj.id}`}` : `/products/${`${formatRoute(obj.name, '-')}-${obj.id}`}`}
															>
																<img
																	className="item-image"
																	src={`${obj.primaryImage ? obj.primaryImage.url : `${process.env.PUBLIC_URL}/assets/images/default-image.png`}`}
																/>
																<Pane className="item-info">
																	<p className="item-name">{obj.name}</p>
																	<PriceItem
																		promotion={obj && obj.promotionPrice}
																		originalPrice={obj && obj.price}
																	/>
																</Pane>
															</Link>
														</Pane>
													})}
												</Pane>
											</Pane>
										</li>
									)
								})
							) : (
								<span>No items</span>
							)}
						</ul>
					)}
					{this.state.loadingQuick && (
						<Pane className="position-relative mt-5"><Loading /></Pane>
					)}
				</Pane>
			</Pane>
		);
	}
}

export default withRouter(injectIntl(N_SearchBox));