import Slider from "react-slick";
import { get, isEmpty } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { Component } from "react";
import { QRCode } from "shared/components";
import Modal from "react-responsive-modal";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Pane, Loading } from "shared/components/layouts";
import { List } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import NotificationSystem from "react-notification-system";
import { ReviewModal, LoginModal } from "shared/components";
import { signin, signinSocial } from 'actions/auth';
import { getReviewsVideo, reviewsVideo } from "actions/reviews";
import { getLikesVideo, likesVideo, getUserLikesVideo } from "actions/likes";
import { formatRoute } from "shared/helpers";
import PropTypes from "prop-types";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
} from "react-share";
import Config from "constants/Config";

class BannerSlideShow extends Component {
  static propTypes = {
    onNotification: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  state = {
    slideshow: [],
    reviewsUser: {
      results: []
    },
    userLikes: 0,
    sliderchange: false,
    activeslide: 0,
    flag: false,
    extVideo: Config.extVideo,
    loading: false,
    loadingLike: false,
    loadingReview: false,
    isPlaying: false,
    isLike: false,
    idStore: null,
    nameBanner: null,
    openModal: false,
    openModalLogin: false,
    openQR: false,
    typeCurrentBanner: 'mobile',
    loadingReady: true,
    playPause: false,
    stateBtnNow: false,
    page: 1,
    isCanLoadMore: true,
    typeSearch: 'Food',
    firstSlide: false,
  };

  changeRoute = () => {
    this.props.history.push(
      this.props.auth.isAuthenticated ? `/learn-more` : "/signup"
    );
  };

  getDetail = () => {
    this.props.getReviewsVideo({
      id: this.state.idStore,
      type: 'users',
    }).then(() => {
      this.setState({ reviewsUser: this.props.reviews })
    });
  }

  getSlides() {
    const params = {
      page: 1,
      type: this.state.type,
      state: this.props.area.user_area.state,
    };
    this.setState({ loading: true })
    this.props.getSlides(params).then(() => {
      const slideshow = [];
      this.props.slides.results &&
        this.props.slides.results.forEach((item, index) => {
          if (index === 0) {
            item.active = true;
          }
          slideshow.push(item);
        });
      this.setState({ slideshow }, () => {
        if (slideshow.length > 0) {
          setTimeout(() => {
            this.tracking(0, true);
          }, 500)
        }
      });
      this.setState({ loading: false })
    });
  }

  componentDidMount() {
    this.setState({ type: this.props.area.type });
    this.setState({ user_state: this.props.area.user_area.state });
    // this.getSlides();
    this._notificationSystem = this.refs.notificationSystem;
  }

  componentWillUnmount() {
    document
      .getElementById("banner-container")
      .removeEventListener("click", () => { });
  }

  playVideo = (id) => {
    let video = document.querySelector(`#banner${id}`)
    let play_pause = document.querySelector(".play_pause")
    let { isPlaying, slideshow, playPause } = this.state;
    let slick_track = document.querySelector(".slick-track");
    this.setState({ playPause: false })
    if (video && isPlaying) {
      video.pause();
      play_pause.innerHTML = "<i class='bx bx-play-circle' ></i>";
      this.setState({ isPlaying: false })
      slick_track.classList.remove('clear-transform');
    } else if (video.readyState == 4) {
      if (id == slideshow[slideshow.length - 1].id) {
        slick_track.classList.add('clear-transform');
      }
      video.play();
      play_pause.innerHTML = "<i class='bx bx-pause-circle' ></i>";
      this.setState({ isPlaying: true });
    }

    setTimeout(() => {
      if (!playPause) {
        this.setState({ playPause: true })
      }
    }, 1300);
  }

  showInfo = (event) => {
    if (event && event.target.currentTime > (event.target.duration - 2)) {
      let ele_info = document.querySelectorAll(".info-stores");
      if (ele_info) {
        ele_info.forEach(element => element.classList.add("d-block"));
        ele_info.forEach(element => element.classList.remove("d-none"));
      }
    }
  }

  tracking = (current, isMount = false) => {
    this.setState({ playPause: false })
    this.setState({ firstSlide: current === 0 });
    var videos = document.getElementsByTagName('video');
    for (var i = 0; i < videos.length; i++) {
      videos[i].pause();
    }
    let ele_info = document.querySelectorAll(".info-stores");
    if (ele_info) {
      ele_info.forEach(element => element.classList.add("d-none"));
      ele_info.forEach(element => element.classList.remove("d-block"));
    }

    this.setState({ isLike: false });
    this.setState({ userLikes: 0 })
    this.setState({ bannerReviews: 0 })
    this.setState({
      reviewsUser: {
        results: []
      }
    });
    let { slideshow, extVideo, page, isCanLoadMore } = this.state;
    let play_pause = document.getElementsByClassName("play_pause")[0]

    if (!isMount && current === slideshow.length - 2 && isCanLoadMore) {
      const params = {
        page: page + 1,
        type: this.state.type,
        state: this.props.area.user_area.state,
      };
      this.props.getSlides(params).then((response) => {
        let banners = [];
        this.props.slides.results &&
          this.props.slides.results.forEach((item, index) => {
            if (index === 0) item.active = true;
            banners.push(item);
          });
        if (banners.length > 0) {
          this.setState((prevState) => ({
            slideshow: [...prevState.slideshow, ...banners],
            page: prevState.page + 1
          }));
          if (page === response.data.meta.totalPages) {
            this.setState({ isCanLoadMore: false })
          }
        }
      });
    }


    this.setState({ typeCurrentBanner: slideshow[current].type })
    this.setState({ nameBanner: slideshow[current].name })
    this.setState({ idStore: slideshow[current].userId }, () => {
      this.getLikeVideo();
      this.getDetail();
      this.fadeInButton();
    })
    if (slideshow[current].image && extVideo.includes(slideshow[current].image.url.substr(slideshow[current].image.url.lastIndexOf(".") + 1))) {
      if (play_pause) {
        play_pause.innerHTML = "<i class='bx bx-play-circle' ></i>"
      }
      this.setState({ playPause: false })

      this.toggleLoadingReady(true)
      let video = document.querySelector(`#banner${slideshow[current].id}`)
      if (video) {
        if (video.currentTime > 0) {
          this.toggleLoadingReady(false)
        }

        let promise = video.play();
        if (promise !== undefined) {
          promise.then(_ => { }).catch(error => {
            video.muted = true;
            video.play();
          });
        }
        this.setState({ playPause: true })

        video.onloadeddata = () => {
          this.setState({ loadingReady: false })
        };
      }
    } else {
      this.setState({ playPause: true })
    }

    this.setState({ isPlaying: true })

    let slick_track = document.querySelector(".slick-track");
    if (slick_track) {
      slick_track.classList.remove('clear-transform');
    }
  }

  fadeInButton = () => {
    this.setState({ stateBtnNow: false })
    setTimeout(() => {
      this.setState({ stateBtnNow: true })
    }, 6000)
  }

  toggleLoadingReady = (isLoading) => {
    if (isLoading) {
      this.setState({ loadingReady: true })
      // this.setState({ playPause: false })
    } else {
      this.setState({ loadingReady: false })
    }
    this.setState({ playPause: false })
  }

  getLikeVideo = async () => {
    await this.props.getLikesVideo({
      id: this.state.idStore,
      model: 'User',
    });
    if (this.props.auth.user.id) {
      await this.props.getUserLikesVideo({
        id: this.state.idStore,
        user_id: this.props.auth.user.id,
        model: 'User',
      }).then(() => {
        this.setState({ isLike: (this.props.like.results.length > 0 && this.props.userLike.results.length > 0 && this.props.userLike.results[0].like === 1 && this.props.like.results[0].objectId === parseInt(this.state.idStore)) })
        this.setState({ loadingLike: false })
        this.setState({ userLikes: this.props.like.results.length })
      })
    } else {
      this.setState({ userLikes: this.props.like.results.length })
    }
  };

  like = () => {
    if (!this.props.auth.user.id) {
      this.onOpenModal('login')
      return false;
    }

    if (this.state.loadingLike) {
      return false;
    }
    this.setState({ isLike: !this.state.isLike }, () => {
      this.setState({ userLikes: this.state.isLike ? (this.state.userLikes + 1) : (this.state.userLikes - 1) })
    })
    this.setState({ loadingLike: true })

    this.props
      .likesVideo({
        id: this.state.idStore,
        model: 'User',
        like: true,
      })
      .then(() => {
        this.getLikeVideo();
      })
  }

  onOpenReview = () => {
    this.onOpenModal('review')
  }

  onOpenModal = (param) => {
    if (param === 'login') {
      this.setState({ openModalLogin: true });
    } else if (param === 'qr') {
      this.setState({ openQR: true });
    } else {
      this.setState({ openModal: true });
    }

    // document.documentElement.style.width = "unset";
  };

  onCloseModal = (param) => {
    if (param === 'login') {
      this.setState({ openModalLogin: false });
    } else if (param === 'qr') {
      this.setState({ openQR: false });
    } else {
      this.setState({ openModal: false });
    }
  };

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  renderSlideProSerItems = (item) => {
    let banner = item;
    let items = item.business.type === Config.BUSINESS_TYPE.FOOD ? item.products : item.services;
    const settingsProSerItems = {
      infinite: items && items.length > 4,
      slidesToShow: 5,
      swipeToSlide: false,
      autoplay: true,
      arrows: false,
      speed: 1500,
      autoplaySpeed: 1500,
      cssEase: "linear",
      pauseOnHover: true
    };
    return (
      <Slider className="banner-slider h-100" ref={c => (this.slider = c)} {...settingsProSerItems}>
        {!isEmpty(items) &&
          (items).map((item, index) => {
            let src = item.primaryImage && !isEmpty(item.primaryImage.url) ? item.primaryImage.url : banner.user.image.url;
            return (
              <Pane key={index} className="slide-item">
                <Link
                  to={item.type === 'Service' ? `/services/${`${formatRoute(item.name, '-')}-${item.id}`}` : `/products/${`${formatRoute(item.name, '-')}-${item.id}`}`}>
                  <Pane className="item-name">
                    {item.name}
                  </Pane>
                  <img
                    className="item-image"
                    src={src}
                  />
                </Link>
              </Pane>
            )
          })
        }
      </Slider>
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.type !== nextProps.area.type || this.state.user_state !== nextProps.area.user_area.state) {
      this.setState({ type: nextProps.area.type, user_state: nextProps.area.user_area.state}, () => {
        this.getSlides();
      })
    }
  }

  render() {
    const settings = {
      autoplay: true,
      speed: 500,
      lazyLoad: true,
      adaptiveHeight: true,
      cssEase: "ease-in-out",
      // vertical: true,
      // verticalSwiping: true,
      autoplaySpeed: 4000,
      arrows: false,
      // infinite: true,
      afterChange: current => this.tracking(current),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            vertical: false,
            fade: false,
            speed: 500,
          },
        },
      ],
    };
    const {
      slideshow, reviewsUser, extVideo, isLike, idStore, nameBanner, openModal,
      openModalLogin, openQR, loading, flag, userLikes, typeCurrentBanner,
      loadingReady, playPause, stateBtnNow, firstSlide
    } = this.state;
    const { auth, signin, signinSocial } = this.props;
    const shareURL = `https://cardbey.com/${idStore}`;
    const slideItem =
      !isEmpty(slideshow) &&
      slideshow.filter((item) => get(item, "image.url")).map((item, index) => {
        let businessType = 'products';
        if(item.business.type === Config.BUSINESS_TYPE.FOOD){
          businessType = 'menu'
        } else if(item.business.type === Config.BUSINESS_TYPE.SERVICE){
          businessType = 'services'
        }
        return (
          <Pane key={index} id={`banner${index}`} className={`${item.id} mobile banner-home h-100`}>
            {extVideo.includes(item.image.url.substr(item.image.url.lastIndexOf(".") + 1)) && (
              <Pane className="position-relative h-100">
                <video onTimeUpdate={this.showInfo} className={`${item.type === 'web' ? 'o-f-contain' : 'o-f-cover'}`} id={`banner${item.id}`} onClick={() => this.playVideo(item.id)} playsInline loop width="100%" height="600">
                  <source src={`${item.image.url}?v=${Math.random()}#t=0.00001`} preload="none" id="video_here" type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
                <Pane className="info-stores d-none">
                  <Pane className="logo-stores">
                    <img
                      style={{ objectFit: "cover" }}
                      src={`${item.user.image.url}`}
                      alt="logo"
                      className="logo-img"
                    />
                    <Pane className="block-qr-code" onClick={() => this.onOpenModal('qr')}>
                      <span className="ic-common-qr-code" />
                    </Pane>
                  </Pane>
                  <Pane className="name-store">
                    <p>
                      {item.business.name || ""}
                    </p>
                  </Pane>
                </Pane>
                <Pane className="slide-items">
                  {!loadingReady && (
                    this.renderSlideProSerItems(item)
                  )}
                </Pane>
                <button onClick={() => this.props.history.push(`/${item.userId}/${businessType}`)} className={`btn btn-now ${stateBtnNow ? 'd-block' : 'd-none'}`}>
                  <FormattedMessage id={`${item.business.type === Config.BUSINESS_TYPE.FOOD ? "menu_store_food" : "menu_store_service"}`} defaultMessage={`${item.business.type === Config.BUSINESS_TYPE.FOOD ? "ORDER NOW" : "BOOK NOW"}`} />
                  <img className="icon-order" src={process.env.PUBLIC_URL + `/assets/images/shopnow/${item.business.type === Config.BUSINESS_TYPE.FOOD ? 'choices' : 'booking'}.png`} />
                </button>
                <Modal
                  className="popup-qrcode"
                  classNames={{
                    closeIcon: 'customIconBtn'
                  }}
                  open={openQR}
                  onClose={() => this.onCloseModal('qr')}
                  center
                  showCloseIcon={true}
                >
                  <QRCode
                    onClose={() => this.onCloseModal('qr')}
                    qrcode={item.user.qrcode.url || ""}
                    storeInfo={item.user}
                    isBanner={true}
                  />
                </Modal>
              </Pane>
            )}
            {!extVideo.includes(item.image.url.substr(item.image.url.lastIndexOf(".") + 1)) && (
              <Pane
                className="banner-item position-relative"
                style={{
                  backgroundImage: `url(${get(item, "image.url")})`,
                }}
              >
                <button onClick={() => this.props.history.push(`/${item.userId}/${item.business.type === Config.BUSINESS_TYPE.FOOD ? 'menu' : 'services'}`)} className={`btn btn-now ${stateBtnNow ? 'd-block' : 'd-none'}`}>
                  <FormattedMessage id={`${get(item, "business.type") === Config.BUSINESS_TYPE.FOOD ? "menu_store_food" : "menu_store_service"}`} defaultMessage={`${get(item, "business.type") === Config.BUSINESS_TYPE.FOOD ? "ORDER NOW" : "BOOK NOW"}`} />
                  <img className="icon-order" src={process.env.PUBLIC_URL + `/assets/images/shopnow/${get(item, "business.type") === Config.BUSINESS_TYPE.FOOD ? 'choices' : 'booking'}.png`} />
                </button>
              </Pane>
            )}
          </Pane>
        )
      });

    return (
      <Pane className="banner-container" id="banner-container">
        <ReviewModal
          onCloseModal={() => this.onCloseModal('review')}
          openModal={openModal}
          onOpenModal={this.onOpenModal}
          auth={auth}
          onReview={this.props.reviewsVideo}
          getDetail={this.getDetail}
          onNotification={this._notificationSystem}
          idStore={idStore}
          nameBanner={nameBanner}
          reviews={reviewsUser}
        />
        <LoginModal
          onNotification={this._notificationSystem}
          signin={signin}
          signinSocial={signinSocial}
          auth={auth}
          onCloseModal={() => this.onCloseModal('login')}
          openModal={openModalLogin}
          isCheckout={true}
        />
        {loading && (
          <Pane className="banner-empty">
            <Loading active inverted />
          </Pane>
        )}
        {!loading && slideItem && (
          <>
            <Pane onClick={() => this.props.history.push(`/search?type=${this.props.area.type}`)} className={`view-all view-all__mobile`}>View all ❯</Pane>
            <Pane className={`block-action w-banner`}>
              <Pane className={`action-button action-mobile`}>
                {slideshow.length > 1 && (
                  <Pane className="button btn-action2" onClick={this.next}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icons/arrow-right.png`}
                      alt="comments"
                      className="arrow-next"
                    />
                  </Pane>
                )}
                {!firstSlide && (
                  <Pane className="button btn-action1" onClick={this.previous}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icons/arrow-left.png`}
                      alt="comments"
                      className="arrow-prev"
                    />
                  </Pane>
                )}
              </Pane>
            </Pane>
            <Slider className="banner-slider h-100" ref={c => (this.slider = c)} {...settings}>
              {slideItem}
            </Slider>
            <Pane className={`block-action w-banner`}>
              <ul className={`d-flex justify-content-end mt-1 action action-mobile`}>
                <li className="mr-4">
                  <Link
                    to={`/${idStore ? idStore : ''}`}
                  ><i className="ic-common-overview font-weight-bold"></i>
                  </Link></li>
                <li className="mr-4 d-flex flex-column align-items-center">
                  <i onClick={this.like} className={`${!isLike ? 'icon-heart-empty-1' : 'icon-heart red-heart'}`}></i>
                  <Pane className="react-num">{userLikes}</Pane>
                </li>
                <li className="mr-4 d-flex flex-column align-items-center">
                  <img
                    onClick={this.onOpenReview}
                    src={`${process.env.PUBLIC_URL
                      }/assets/images/icons/comments.svg`}
                    alt="comments"
                    className="icon-down"
                  />
                  <Pane className="react-num mt-0">{reviewsUser.results.length}</Pane>
                </li>
                <li>
                  <i onClick={() => this.setState({ flag: !this.state.flag })} className="icon ic-common-share font-weight-bold position-relative">
                    <List horizontal className={`share-list ${flag ? 'd-flex' : 'hide'} `}>
                      <List.Content>
                        <FacebookShareButton className="share-item" url={shareURL}>
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                      </List.Content>
                      <List.Content>
                        <TwitterShareButton className="share-item" url={shareURL}>
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                      </List.Content>
                      <List.Content>
                        <EmailShareButton className="share-item" url={shareURL}>
                          <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                      </List.Content>
                      <List.Content>
                        <LinkedinShareButton className="share-item" url={shareURL}>
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                      </List.Content>
                    </List>
                  </i></li>
              </ul>
            </Pane>
          </>
        )}
        <Pane onClick={() => this.props.history.push(`/search?type=${this.props.area.type}`)} className={`view-all__sp`}>View all ❯</Pane>
        {!loading && !slideItem && (
          <>
            <Pane onClick={() => this.props.history.push(`/search?type=${this.props.area.type}`)} className={`view-all view-all__mobile`}>View all ❯</Pane>
            <Pane className="banner-empty">
              No
              collections
              have
              been
              chosen
              yet!
            </Pane>
          </>
        )}
        <Pane className={`loading-ready ${loadingReady && slideItem && typeCurrentBanner === 'mobile' ? 'd-block' : 'd-none'}`}>
          <Loading active inverted />
        </Pane>
        <Pane className={`play_pause ${(playPause == false && loadingReady == false && slideItem) ? 'd-block' : 'd-none'}`}>
          <Pane className="icon">
            <i className='bx bx-play-circle'></i>
          </Pane>
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  auth: state.auth,
  reviews: state.reviews,
  like: state.likes,
  userLike: state.userLike,
  slides: state.banners,
  area: state.area
});

const bindDispatchToProps = (dispatch) => ({
  signin: bindActionCreators(signin, dispatch),
  signinSocial: bindActionCreators(signinSocial, dispatch),
  getReviewsVideo: bindActionCreators(getReviewsVideo, dispatch),
  reviewsVideo: bindActionCreators(reviewsVideo, dispatch),
  getLikesVideo: bindActionCreators(getLikesVideo, dispatch),
  likesVideo: bindActionCreators(likesVideo, dispatch),
  getUserLikesVideo: bindActionCreators(getUserLikesVideo, dispatch),
});

export default withRouter(connect(bindStateToProps, bindDispatchToProps)(BannerSlideShow));
