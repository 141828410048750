import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { MasterLayout } from 'components/layout';
import { getBanners } from 'actions/banner';
import { Home } from 'components/scenes/home';

class HomePage extends Component {
  static propTypes = {
    slides: PropTypes.object,
    getSlides: PropTypes.func
  };
  

  render() {
    return (
      <MasterLayout>
        <Home getSlides={this.props.getSlides} />
      </MasterLayout>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  getSlides: bindActionCreators(getBanners, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
