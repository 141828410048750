import React, { Component } from "react";
import { Pane } from "shared/components/layouts";
import {
  LeftArea,
  Newsletter,
  CategoryList,
  BestPicksList,
  FeatureProduct,
  BannerSlideShow,
  BusinessLogoList,
} from ".";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getVideos } from "actions/myVideos";
import { getProductCategories, getServiceCategories } from "actions/category";

import Config from "constants/Config";

class Home extends Component {
  PropTypes = {
    getProductCategories: PropTypes.func.isRequired,
    getServiceCategories: PropTypes.func.isRequired,
    productCats: PropTypes.object,
    serviceCats: PropTypes.object,
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      type: Config.PRODUCT_TYPE,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({loading: true})
    // this.props.getProductCategories({
    //   id: "30,5,28,124,45,32,91,16,90,4,89,82",
    //   sort: "name",
    // }).then(() => {
    //   this.setState({loading: false})
    // });
    // this.props.getVideos({
    //   page: 1,
    //   size: 20
    // });
  }

  onChangeCategory = (type) => {
    this.setState({
      type,
    });
    if (type === Config.PRODUCT_TYPE) {
      this.setState({loading: true})
      this.props.getProductCategories({
        id: "30,5,28,124,45,32,91,16,90,4,89,82",
        sort: "name",
      }).then(() => {
        this.setState({loading: false})
      });
    } else if (type === Config.SERVICE_TYPE) {
      this.setState({loading: true})
      this.props.getServiceCategories({
        id: "88,87,86,85,84,81,79,77,73,72,71,67",
        sort: "name",
      }).then(() => {
        this.setState({loading: false})
      });
    } else if (type === Config.VIDEO_TYPE) {
      this.setState({loading: true})
      this.props.getVideos({ page: 1, size: 10 }).then(() => {
        this.setState({loading: false})
      });
    }
  };

  render() {
    const { productCats, serviceCats, videos } = this.props;
    const { type, loading } = this.state;


    return (
      <Pane className="homepage-container">
        <BannerSlideShow getSlides={this.props.getSlides} />
        <Pane className="main-container">
          <BusinessLogoList />
          {/* <BestPicksList videos={videos} /> */}
          {/* <FeatureProduct
            loading={loading}
            type={type}
            onChangeCategory={this.onChangeCategory}
            videos={this.props.videos}
          />
          {type !== Config.VIDEO_TYPE && (
            <CategoryList
              type={type}
              categories={
                type === Config.PRODUCT_TYPE ? productCats : serviceCats
              }
            />
          )}
          {type === Config.VIDEO_TYPE && (
            <CategoryList
              type={Config.PRODUCT_TYPE}
              categories={
                type === Config.PRODUCT_TYPE ? productCats : serviceCats
              }
            />
          )} */}
           {/* <Newsletter /> */}
          {/* <LeftArea /> */}
        </Pane>
      </Pane>
    );
  }
}
Home.propTypes = {
  getProductCategories: PropTypes.func.isRequired,
  productCats: PropTypes.object,
  videos: PropTypes.object,
};

const bindStateToProps = (state) => ({
  productCats: state.productCategories,
  serviceCats: state.serviceCategories,
  videos: state.videos,
});

const bindDispatchToProps = (dispatch) => ({
  getProductCategories: bindActionCreators(getProductCategories, dispatch),
  getServiceCategories: bindActionCreators(getServiceCategories, dispatch),
  getVideos: bindActionCreators(getVideos, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(Home);

// export default Home;
