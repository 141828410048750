import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { List } from "semantic-ui-react";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  ViberIcon,
  ViberShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LineIcon,
  LineShareButton
} from "react-share";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import {
  Form,
  Button,
  Message,
  Radio,
  Icon,
  Dimmer,
  Checkbox,
} from "semantic-ui-react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import Modal from "react-responsive-modal";
import validator from "validator";
import NotificationSystem from "react-notification-system";
import { isEmpty, get } from "lodash";
import { Pane, Loading } from "shared/components/layouts";
import {
  updateInfo,
  updateAvatar,
  updateBackground,
  changePassword,
} from "actions/user";
import { getUserInfo } from "actions/auth";
import {
  getMyBusinessInfo,
  updateMyBusinessInfo,
  createMyBusinessInfo,
} from "actions/myBusiness";
import {
  getFoodOrderCategories,
  getProductCategories,
  getServiceCategories,
} from "actions/category";
import TreeSelect from "rc-tree-select";
import { QRCode } from "shared/components";
import { handleError } from "shared/helpers";
import PhoneInput from "react-phone-input-2";
import foodOrderAdapter from "services/categories/adapter";
import InlineMessage from "shared/components/InlineMessage";
import TradingHourItem from "../services/create/TradingHourItem";
import ReactTooltip from "react-tooltip";
import queryString from "query-string";
import Config from "constants/Config";
import Select from "react-select";

const options = [
  {
    value: "Food",
    name: "Food",
  },
  {
    value: "Product",
    name: "Product",
  },
  {
    value: "Service",
    name: "Service",
  },
];
class Settings extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    getUserInfo: PropTypes.func,
    updateUserInfo: PropTypes.func,
    getMyBusinessInfo: PropTypes.func,
    updateMyBusinessInfo: PropTypes.func,
    createBusinessInfo: PropTypes.func,
    updateAvatar: PropTypes.func,
    updateBackground: PropTypes.func,
    changePassword: PropTypes.func,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const {
      location: { search },
    } = props;
    this._notificationSystem = null;

    this.state = {
      showBusinessQuery: queryString.parse(search).showBusiness || false,
      userInfo: {},
      data: {
        email: "",
        name: "",
        description: "",
        sub_title: "",
        url_website: "",
        position: "",
        phone: "",
        address: "",
        postCode: "",
        country: "",
        lat: "",
        lng: "",
        businessId: "",
        businessEmail: "",
        businessName: "",
        businessSubTitle: "",
        businessDescription: "",
        businessAddress: "",
        businessState: "",
        businessCity: "",
        businessPhone: "",
        businessPostCode: "",
        businessLat: "",
        businessLng: "",
        store_website: "",
        oldPassword: "",
        password: "",
        confirmPassword: "",
        type: "Food",
        category_id: undefined,
      },
      tradingHour: {
        Mon: {
          from: "",
          to: "",
          off: false,
        },
        Tue: {
          from: "",
          to: "",
          off: false,
        },
        Wed: {
          from: "",
          to: "",
          off: false,
        },
        Thu: {
          from: "",
          to: "",
          off: false,
        },
        Fri: {
          from: "",
          to: "",
          off: false,
        },
        Sat: {
          from: "",
          to: "",
          off: false,
        },
        Sun: {
          from: "",
          to: "",
          off: false,
        },
      },
      openModal: false,
      openQRCodeModal: false,
      errors: {},
      messageChangePassword: "",
      showBusiness: false,
      loading: false,
      loadingAvatar: false,
      loadingBackground: false,
      loadingChange: false,
      categories: [],
      loadingIsActive: false,
      is_active: 0,
      extVideo: Config.extVideo,
      flag: false,
    };
  }

  onChange = (e) => {

    const { name, checked } = e.target;
    if(name == 'firstScanFlg'){
      this.setState({
        data: { ...this.state.data, [name]: checked ? 1 : 0 },
      });
    } else {
      this.setState({
        data: { ...this.state.data, [e.target.name]: e.target.value },
      });
    }
    this.setState({ errors:{ ...this.state.errors, [e.target.name]: '' } });
  };

  onChangeRadio = (e, { value }) => {
    this.setState({
      data: { ...this.state.data, country: value },
    });
  };

  onChangePhoneBusiness = (businessPhone) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        businessPhone,
      },
    }));
    this.setState({ errors:{ ...this.state.errors, [businessPhone]: '' } });
  };

  onChangePhone = (phone) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        phone,
      },
    }));
  };

  onChangeAddress = (address) => {
    this.setState({
      data: { ...this.state.data, address },
    },
    () => {
      geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          this.setState((prevState) => ({
            data: {
              ...prevState.data,
              lat: latLng.lat,
              lng: latLng.lng,
            },
          }));
        })
        .catch(() => { });
    });
  };

  onChangeBusinessAddress = (businessAddress) => {
    const { intl } = this.props;
    if (!businessAddress) {
      this.setState({
        errors: { ...this.state.errors, businessAddress: intl.formatMessage({
          id: "validate.require_field",
        }) },
      })
    } else {
      this.setState({
        errors: { ...this.state.errors, businessAddress: '' },
      })
    }

    this.setState(
      {
        data: { ...this.state.data, businessAddress },
      },
      () => {
        geocodeByAddress(businessAddress)
          .then((results) => getLatLng(results[0]))
          .then((latLng) => {
            this.setState((prevState) => ({
              data: {
                ...prevState.data,
                businessLat: latLng.lat,
                businessLng: latLng.lng,
              },
            }));
          })
          .catch(() => { });
      }
    );
  };

  toggle = () => {
    this.setState({
      showBusiness: !this.state.showBusiness,
    });
  };

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      let ele = document.getElementById("leftnotiarea");
      if (ele) {
        ele.style.display = "inline-block";
      }
    });
  }

  componentDidMount() {
    let ele = document.getElementById("leftnotiarea");
    if (ele) {
      ele.style.display = "none";
    }
    this._notificationSystem = this.refs.notificationSystem;
    const { userInfo } = this.props;
    this.setState({
      data: {
        ...this.state.data,
        id: userInfo.id,
        email: userInfo.email || "",
        name: userInfo.displayName || "",
        sub_title: userInfo.sub_title || "",
        description: userInfo.description || "",
        url_website: userInfo.url_website || "",
        position: userInfo.position || "",
        phone: userInfo.phoneNumber || "",
        address: userInfo.address || "",
        postCode: userInfo.postCode || "",
        country: userInfo.country || "",
        lat: userInfo.lat || "",
        lng: userInfo.lng || "",
        businessId: userInfo.business.id || "",
        businessName: userInfo.business.name || "",
        businessEmail: userInfo.business.email || "",
        businessSubTitle: userInfo.business.subTitle || "",
        businessDescription: userInfo.business.description || "",
        businessPhone: userInfo.business.phone || "",
        businessAddress: userInfo.business.address || "",
        businessState: userInfo.business.state || "",
        businessCity: userInfo.business.city || "",
        businessPostCode: userInfo.business.postCode || "",
        businessLat: userInfo.business.lat || "",
        businessLng: userInfo.business.lng || "",
        businesWebsite: userInfo.business.websiteUrl || "",
        firstScanFlg: userInfo.business.firstScanFlg,
        firstScanDiscount: userInfo.business.firstScanDiscount,
        type: userInfo.business.type == "Business" ? "Food" : userInfo.business.type,
        category_id: get(userInfo.business, "category_id"),
      },
      is_active: userInfo.business.is_active || 0,
      tradingHour: userInfo.business.tradingHours
        ? this.convertTradingHoursFromServer(userInfo.business.tradingHours)
        : this.state.tradingHour,
    });
    if (
      userInfo.business.type === "Food" ||
      userInfo.business.type === "Business" ||
      !userInfo.business.type
    ) {
      this.props
        .getFoodOrderCategories({
          parent_id: null,
        })
        .then((response) => {
          this.setState({
            categories: foodOrderAdapter.foodOrderCategoryBrowse.parseResponse(
              response.data
            ),
          });
        });
    }
    if (userInfo.business.type === "Product") {
      this.props.getProductCategories({ group: "Product" }).then((response) => {
        this.setState({
          categories: foodOrderAdapter.foodOrderCategoryBrowse.parseResponse(
            response.data
          ),
        });
      });
    }
    if (userInfo.business.type === "Service") {
      this.props.getServiceCategories().then((response) => {
        this.setState({
          categories: foodOrderAdapter.foodOrderCategoryBrowse.parseResponse(
            response.data
          ),
        });
      });
    }

    if (this.state.showBusinessQuery) {
      this.createBuniessInfo();
    }

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;
    if (!data.name) {
      errors.name = intl.formatMessage({ id: "validate.require_field" });
    }
    if (data.postCode && !validator.isNumeric(data.postCode)) {
      errors.postCode = intl.formatMessage({
        id: "validate.invalid_post_code",
      });
    }

    if (this.state.showBusiness) {
      // if (!data.businessEmail) {
      //   errors.businessEmail = intl.formatMessage({
      //     id: "validate.require_field",
      //   });
      // }

      if (!data.type) {
        errors.type = intl.formatMessage({
          id: "validate.require_field",
        });
      }

      if (!data.category_id) {
        errors.category_id = intl.formatMessage({
          id: "validate.require_field",
        });
      }

      if (data.businessEmail && !validator.isEmail(data.businessEmail)) {
        errors.businessEmail = intl.formatMessage({
          id: "validate.invalid_email",
        });
      }

      if (!data.businessName) {
        errors.businessName = intl.formatMessage({
          id: "validate.require_field",
        });
      }

      if (!data.businessAddress) {
        errors.businessAddress = intl.formatMessage({
          id: "validate.require_field",
        });
      }

      if (!data.businessState) {
        errors.businessState = intl.formatMessage({
          id: "validate.require_field",
        });
      }

      if (!data.businessPhone) {
        errors.businessPhone = intl.formatMessage({
          id: "validate.require_field",
        });
      }

      // eslint-disable-next-line no-useless-escape
      const regex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{6,}$/g;
      // if (regex.test(data.businessName)) {
      //   errors.businessName = intl.formatMessage({
      //     id: "validate.require_field",
      //   });
      // }

      if (!data.businessPostCode) {
        errors.businessPostCode = intl.formatMessage({
          id: "validate.require_field",
        });
      } else if (!validator.isNumeric(data.businessPostCode)) {
        errors.businessPostCode = intl.formatMessage({
          id: "validate.invalid_post_code",
        });
      }
    }
    return errors;
  };

  validatePassword = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.oldPassword) {
      errors.oldPassword = intl.formatMessage({ id: "validate.require_field" });
    }
    if (!data.password) {
      errors.password = intl.formatMessage({ id: "validate.require_field" });
    }
    if (
      data.password &&
      (data.password.length < 6 ||
        data.password.search(/[0-9]/) < 0 ||
        data.password.search(/[A-Z]/) < 0 ||
        data.password.search(/[a-z]/) < 0)
    ) {
      errors.password = intl.formatMessage({ id: "validate.invalid_password" });
    }
    if (!data.confirmPassword) {
      errors.confirmPassword = intl.formatMessage({
        id: "validate.require_field",
      });
    }
    if (data.confirmPassword !== data.password) {
      errors.confirmPassword = intl.formatMessage({
        id: "validate.invalid_confirm_password",
      });
    }

    return errors;
  };

  onOpenModal = () => {
    this.setState({
      messageChangePassword: "",
      openModal: true,
    });
  };

  onCloseModal = () => {
    this.setState({
      openModal: false,
    });
  };

  onOpenQRCodeModal = () => {
    this.setState({
      openQRCodeModal: true,
    });
  };

  onCloseQRCodeModal = () => {
    this.setState({
      openQRCodeModal: false,
    });
  };

  changePassword = () => {
    const errors = this.validatePassword(this.state.data);
    const { intl } = this.props;

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loadingChange: true });

      const data = {
        id: this.state.data.id,
        current_password: this.state.data.oldPassword,
        password: this.state.data.password,
        password_confirmation: this.state.data.confirmPassword,
      };

      this.props
        .changePassword(data)
        .then((response) => {
          this.setState({
            loadingChange: false,
            data: {
              ...this.state.data,
              oldPassword: "",
              password: "",
              confirmPassword: "",
            },
          });

          this.setState({ openModal: false });
          this._notificationSystem.addNotification({
            message: intl.formatMessage({
              id: "notification.your_password_has_been_changed_successfully",
            }),
            level: "success",
          });
        })
        .catch((error) => {
          errors.message = intl.formatMessage({
            id: "notification.please_try_again",
          });
          this.setState({
            loadingChange: false,
            errors,
          });
        });
    }
  };

  convertTradingHoursFromServer(tradingHourServer) {
    const tradingHours = {
      Mon: {
        from: "",
        to: "",
        off: false,
      },
      Tue: {
        from: "",
        to: "",
        off: false,
      },
      Wed: {
        from: "",
        to: "",
        off: false,
      },
      Thu: {
        from: "",
        to: "",
        off: false,
      },
      Fri: {
        from: "",
        to: "",
        off: false,
      },
      Sat: {
        from: "",
        to: "",
        off: false,
      },
      Sun: {
        from: "",
        to: "",
        off: false,
      },
    };

    Object.keys(tradingHourServer).forEach((item) => {
      const key = item.charAt(0).toUpperCase() + item.slice(1);
      // if (this.state.tradingHour[item].off || !this.state.tradingHour[item].from || !this.state.tradingHour[item].to) {
      if (!tradingHourServer[item].from || !tradingHourServer[item].to) {
        tradingHours[key].from = "00:00";
        tradingHours[key].to = "01:00";
        tradingHours[key].off = true;
      } else {
        tradingHours[key].from = tradingHourServer[item].from;
        tradingHours[key].to = tradingHourServer[item].to;
        tradingHours[key].off = tradingHourServer[item].off;
      }
    });
    return tradingHours;
  }

  onSubmit = async () => {
    let errors = {};
    const { intl, userInfo } = this.props;
    errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      const tradingHours = {
        Mon: {
          from: "",
          to: "",
          off: false,
        },
        Tue: {
          from: "",
          to: "",
          off: false,
        },
        Wed: {
          from: "",
          to: "",
          off: false,
        },
        Thu: {
          from: "",
          to: "",
          off: false,
        },
        Fri: {
          from: "",
          to: "",
          off: false,
        },
        Sat: {
          from: "",
          to: "",
          off: false,
        },
        Sun: {
          from: "",
          to: "",
          off: false,
        },
      };

      Object.keys(this.state.tradingHour).forEach((item) => {
        const key = item.charAt(0).toUpperCase() + item.slice(1);
        // if (this.state.tradingHour[item].off || !this.state.tradingHour[item].from || !this.state.tradingHour[item].to) {
        if (
          !this.state.tradingHour[item].from ||
          !this.state.tradingHour[item].to
        ) {
          tradingHours[key].from = "00:00";
          tradingHours[key].to = "01:00";
          tradingHours[key].off = true;
        } else {
          tradingHours[key].from = this.state.tradingHour[item].from;
          tradingHours[key].to = this.state.tradingHour[item].to;
          tradingHours[key].off = this.state.tradingHour[item].off;
        }
      });

      this.setState({ loading: true });

      const data = new FormData();

      data.append("id", this.state.data.id);
      data.append("fullname", this.state.data.name.trim() || "");
      data.append("description", this.state.data.description.trim() || "");
      data.append("sub_title", this.state.data.sub_title.trim() || "");
      data.append("url_website", this.state.data.url_website.trim() || "");
      data.append("phone", this.state.data.phone.trim() || "");
      data.append("address", this.state.data.address.trim() || "");
      data.append("position", this.state.data.position.trim() || "");
      data.append("postcode", this.state.data.postCode.trim() || "");
      data.append("country", this.state.data.country.trim() || "");
      data.append("lat", this.state.data.lat);
      data.append("lng", this.state.data.lng);
      //data.append('trading_hours', JSON.stringify(tradingHours))

      await this.props
        .updateUserInfo(data)
        .then((res) => {
          if (this.state.showBusiness) {
            const data = {
              id: userInfo.business.id,
              name: this.state.data.businessName.trim() || "",
              email: this.state.data.businessEmail.trim() || "",
              phone: this.state.data.businessPhone.trim() || "",
              address: this.state.data.businessAddress.trim() || "",
              state: this.state.data.businessState.trim() || "",
              city: this.state.data.businessCity.trim() || "",
              postcode: this.state.data.businessPostCode.trim() || "",
              lat: this.state.data.businessLat || "",
              lng: this.state.data.businessLng || "",
              description: this.state.data.businessDescription.trim() || "",
              sub_title: this.state.data.businessSubTitle.trim() || "",
              type: this.state.data.type,
              category_id: this.state.data.category_id,
              trading_hours: JSON.stringify(tradingHours),
              url_website: this.state.data.businesWebsite,
              first_scan_flg: this.state.data.firstScanFlg,
              first_scan_discount: this.state.data.firstScanDiscount,
            };

            this.props
              .updateMyBusinessInfo(data)
              .then(() => {
                this.props.getUserInfo({ userId: this.state.data.id }).then(() => {
                  this.setState({ loading: false });
                  this._notificationSystem.addNotification({
                    message: intl.formatMessage({ id: "notification.successfully" }),
                    level: "success",
                    onRemove: () => {
                      // this.props.history.push("overview");
                    },
                  });
                  setTimeout(() => {
                    if (this.state.showBusiness) {
                      window.location.href += "?showBusiness=true";
                    } else {
                      window.location.reload();
                    }
                  }, 1000);
                });
              })
              .catch((errors) => {
                this.setState({ loading: false });
                this._notificationSystem.addNotification({
                  message: handleError(
                    errors,
                    intl.formatMessage({ id: "notification.please_try_again" })
                  ),
                  level: "error",
                });
              });
          } else {
            this.setState({ loading: false });
            this._notificationSystem.addNotification({
              message: intl.formatMessage({ id: "notification.successfully" }),
              level: "success",
              onRemove: () => {
                // this.props.history.push("overview");
              },
            });
            setTimeout(() => {
              if (this.state.showBusiness) {
                window.location.href += "?showBusiness=true";
              } else {
                window.location.reload();
              }
            }, 1000);
          }
        })
        .catch((errors) => {
          this.setState({ loading: false });
          this._notificationSystem.addNotification({
            message: handleError(
              errors,
              intl.formatMessage({ id: "notification.please_try_again" })
            ),
            level: "error",
          });
        });
    }
  };

  onChangeHours = (month, name, value) => {
    this.setState({
      tradingHour: {
        ...this.state.tradingHour,
        [month]: {
          ...this.state.tradingHour[month],
          [name]: value,
          // off: false
        },
      },
    });
  };

  onChangeToggle = (month, name, value) => {
    this.setState({
      tradingHour: {
        ...this.state.tradingHour,
        [month]: {
          ...this.state.tradingHour[month],
          [name]: value,
          // off: false
        },
      },
    });
  };

  onChangeType = (event) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          type: event.target.value,
          category_id: undefined,
        },
      },
      () => {
        if (this.state.data.type === "Food") {
          this.props
            .getFoodOrderCategories({
              parent_id: null,
            })
            .then((response) => {
              this.setState({
                categories: foodOrderAdapter.foodOrderCategoryBrowse.parseResponse(
                  response.data
                ),
              });
            });
        }
        if (this.state.data.type === "Product") {
          this.props
            .getProductCategories({ group: "Product" })
            .then((response) => {
              this.setState({
                categories: foodOrderAdapter.foodOrderCategoryBrowse.parseResponse(
                  response.data
                ),
              });
            });
        }
        if (this.state.data.type === "Service") {
          this.props.getServiceCategories().then((response) => {
            this.setState({
              categories: foodOrderAdapter.foodOrderCategoryBrowse.parseResponse(
                response.data
              ),
            });
          });
        }
      }
    );
  };

  onSelectTreeSelect = (value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        category_id: value,
      },
    }));
  };

  nestPath = (data) => {
    if (!isEmpty(data)) {
      return data.map((item, index) => ({
        index,
        key: item.id,
        pId: item.id,
        title: item.name,
        value: item.id,
        disabled: !isEmpty(item.children),
        children: this.nestPath(item.children),
      }));
    }
    return [];
  };

  onSetOff = (data) => {
    if (!isEmpty(data)) {
      const dataNew = Object.keys(data).map((key) => {
        if (!data[key].off) {
          return {
            [key]: {
              ...data[key],
              off: false,
            },
          };
        }
        return {
          [key]: data[key],
        };
      });
      return Object.assign({}, ...dataNew);
    }
    return {};
  };

  createBuniessInfo = () => {
    this.setState({ showBusiness: true });
    const elementScroll = document.getElementById("desc-business");
    if (elementScroll && this.state.showBusinessQuery) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
        this.props.history.replace(this.props.location.pathname)
        let ele = document.getElementById("leftnotiarea");
        if (ele) {
          ele.style.display = "none";
        }
      }, 1000)
    } else if (elementScroll) {
      elementScroll.scrollIntoView({ behavior: "smooth" });
    }
  }

  toggleActive = (e, { value }) => {
    const { intl } = this.props;
    let text = intl.formatMessage({ id: "confirm_account_type" });

    if (!window.confirm(text) == true) {
      return false;
    }
    this.setState(({
      is_active: value,
    }), () => {
      this.onSubmitToggleActive();
    });
  };

  onSubmitToggleActive = async () => {
    const { intl, userInfo } = this.props;

    this.setState({ loadingIsActive: true });

    const data = {
      id: userInfo.business.id,
      is_active: this.state.is_active,
    };

    await this.props
      .updateMyBusinessInfo(data)
      .then((res) => {
        this.props.getUserInfo({ userId: this.state.data.id }).then(() => {
          this.setState({ loadingIsActive: false });
          this._notificationSystem.addNotification({
            message: intl.formatMessage({ id: "notification.successfully" }),
            level: "success"
          });
          window.location.reload();
        })
      })
      .catch((errors) => {
        this.setState({ loadingIsActive: false, is_active: !this.state.is_active });
        this._notificationSystem.addNotification({
          message: handleError(
            errors,
            intl.formatMessage({ id: "notification.please_try_again" })
          ),
          level: "error",
        });
      });
  };

  updatedStates = () => {
    return this.props.area.areas.states.map((state) => ({ label: state.name, value: state.isoCode, ...state })) || [];
  }

  updatedCities = (stateId) => {
    return this.props.area.areas.cities.filter(city => city.stateCode === stateId).map((city) => ({ label: city.name, value: city.name, ...city })) || [];
  }

  onChangeLocation = (type, value) => {
    if (value) {
      if (type === 'State') {
        this.setState({ data: { ...this.state.data, businessState: value, businessCity: null } })

      } else {
        this.setState({ data: { ...this.state.data, businessCity: value } })
      }

    }
  }

  copyLink = (id, code) => {
    let copyText = window.location.origin + `/${id}/reference?code=${code}`;
    navigator.clipboard.writeText(copyText);
  }

  render() {
    const { intl, userInfo } = this.props;
    const { errors, data, loading, loadingChange, is_active, loadingIsActive, extVideo } = this.state;

    let shareURL = window.location.origin + `/${userInfo.id}/reference?code=${userInfo.uuid}`;
    const renderFunc = ({
      getInputProps,
      getSuggestionItemProps,
      suggestions,
    }) => (
      <Pane className="autocomplete-root form-item">
        <input {...getInputProps()} className="form-input" placeholder="" />
        <Pane className="autocomplete-dropdown-container">
          {suggestions.map((suggestion, index) => (
            <Pane {...getSuggestionItemProps(suggestion)} key={index}>
              <span>{suggestion.description}</span>
            </Pane>
          ))}
        </Pane>
      </Pane>
    );


    return (
      <Pane className="overview-container pb-5" id="anchor">
        <Modal
          open={this.state.openModal}
          onClose={this.onCloseModal}
          center
          showCloseIcon={true}
        >
          <Pane className="card-body popup-body-content rows">
            <Pane className="col-md-12">
              <h3>
                <FormattedMessage
                  id="change_your_password"
                  defaultMessage="Change your password"
                />
              </h3>
              <Form onSubmit={this.changePassword}>
                <Form.Field className="form-group" error={!!errors.oldPassword}>
                  <label htmlFor="oldPassword">
                    <FormattedMessage
                      id="old_password"
                      defaultMessage="Old Password"
                    />
                  </label>
                  <input
                    type="password"
                    id="oldPassword"
                    className="form-control"
                    name="oldPassword"
                    placeholder={intl.formatMessage({
                      id: "old_password",
                      defaultMessage: "Old password",
                    })}
                    value={data.oldPassword}
                    onChange={this.onChange}
                  />
                  {errors.oldPassword && (
                    <InlineMessage text={errors.oldPassword} />
                  )}
                </Form.Field>
                <Form.Field className="form-group" error={!!errors.password}>
                  <label htmlFor="password">
                    <FormattedMessage
                      id="new_password"
                      defaultMessage="New password"
                    />
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    name="password"
                    placeholder={intl.formatMessage({
                      id: "password",
                      defaultMessage: "Password",
                    })}
                    value={data.password}
                    onChange={this.onChange}
                  />
                  <span className="form-note pt5">
                    <FormattedMessage
                      id="notification.require_character"
                      defaultMessage="Your passwords must be contain at least 6 characters, a minimum of 1 uppercase character and a numeric character."
                    />
                  </span>
                  {errors.password && <InlineMessage text={errors.password} />}
                </Form.Field>
                <Form.Field
                  className="form-group"
                  error={!!errors.confirmPassword}
                >
                  <label htmlFor="confirmPassword">
                    <FormattedMessage
                      id="confirm_new_password"
                      defaultMessage="Confirm new password"
                    />
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    className="form-control"
                    name="confirmPassword"
                    placeholder={intl.formatMessage({
                      id: "confirm_password",
                      defaultMessage: "Confirm password",
                    })}
                    value={data.confirmPassword}
                    onChange={this.onChange}
                  />
                  {errors.confirmPassword && (
                    <InlineMessage text={errors.confirmPassword} />
                  )}
                </Form.Field>
                {errors.message && <InlineMessage text={errors.message} />}
                {this.state.messageChangePassword && (
                  <Message color="green">
                    {this.state.messageChangePassword}
                  </Message>
                )}
                <Button
                  type="submit"
                  className="submit btn btn-circle-primary"
                  style={{ color: "white" }}
                  disabled={loadingChange}
                  loading={loadingChange}
                  fluid
                >
                  <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
              </Form>
            </Pane>
          </Pane>
        </Modal>
        <Pane className="settings-container">
          <Pane className="clearfix" />
          <Pane className="row">
            <Pane className="col-lg-6">
              <h3 className="block-title pt10 text-uppercase">
                <FormattedMessage id="settings" defaultMessage="Settings" />
              </h3>
            </Pane>
            {userInfo.business.is_active == 0 && isEmpty(userInfo.business.name) && (
              <Pane className="col-lg-6 btn-create-business">
                <Button
                  onClick={this.createBuniessInfo}
                  type="button"
                  style={{ color: "white" }}
                  className="btn btn-circle-primary"
                  data-tip data-for={`tooltip-business`}
                >
                  <FormattedMessage
                    id="create_business"
                    defaultMessage="Create business account"
                  />
                </Button>
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="float"
                  id={"tooltip-business"}
                >
                  You need to complete the business information to show your store
                </ReactTooltip>
              </Pane>
            )}
          </Pane>
          {!isEmpty(userInfo.business.name) && (
            <Pane className="row">
              {loadingIsActive && (
                <Dimmer active inverted>
                  <Loading />
                </Dimmer>
              )}
              {!loadingIsActive && (
                <Pane className="col-lg-6">
                  <Form>
                    <Pane className="d-flex mb-3">
                      <Form.Field className="choose-item mr-4">
                        <Checkbox
                          radio
                          label={intl.formatMessage({
                            id: "normal_account",
                            defaultMessage: "Normal Account",
                          })}
                          name="is_active"
                          value={0}
                          checked={is_active === 0}
                          onChange={this.toggleActive}
                        />
                      </Form.Field>
                      <Form.Field className="choose-item">
                        <Checkbox
                          radio
                          label={intl.formatMessage({
                            id: "business_account",
                            defaultMessage: "Business Account",
                          })}
                          name="is_active"
                          value={1}
                          checked={is_active === 1}
                          onChange={this.toggleActive}
                        />
                      </Form.Field>
                    </Pane>
                  </Form>
                </Pane>
              )}
              <Pane className="col-lg-6">
                <Button
                  type="button"
                  style={{ color: "white" }}
                  className="submit btn btn-circle-primary float-right"
                  onClick={this.onOpenModal}
                >
                  <FormattedMessage
                    id="change_your_password"
                    defaultMessage="Change your password"
                  />
                </Button>
              </Pane>
            </Pane>
          )}
          <Form onSubmit={this.onSubmit}>
            <Pane className="form-overview">
              <Pane className="form-content">
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item">
                      <label htmlFor="email" className="form-label">My Reference code</label>
                      <Pane className="row">
                        <Pane className="col-10">
                          <input
                            disabled
                            type="text"
                            className="form-input"
                            value={`${window.location.origin}/${userInfo.id}/reference?code=${userInfo.uuid}`}
                          />
                          <label className="text-share-link">
                            Here is your exclusive referral link to sign up to instantly get a 5% discount on your service fees. Plus, when any of your friends use our services through your link, you will get 10% cashback based on the value of your friend’s purchase.
                          </label>
                        </Pane>
                        <Pane className="col-2">
                          <Button type='button' className='btn btn-default btn-url'><span className="cursor-pointer" onClick={() => this.copyLink(userInfo.id, userInfo.uuid)}>
                              <img src={process.env.PUBLIC_URL + `/assets/images/icons/copy.svg`}
                                  style={{ width: '20px' }}
                                  alt="copy"
                                  className="icon-copy"
                              />
                            </span>
                          </Button>
                          <Button type='button' className='btn btn-default btn-url' onClick={() => this.setState({ flag: !this.state.flag })}  data-tip data-for={`share`}>
                            <span className=" ">
                                <div className="box-icon" >
                                  <span className="icon ic-common-share" />
                                </div>

                              <List horizontal className={`share-list share-list-ref ${this.state.flag ? 'd-flex' : 'hide'} `}>
                                <List.Content>
                                  <FacebookShareButton className="share-item" url={shareURL}>
                                    <FacebookIcon size={32} round={true} />
                                  </FacebookShareButton>
                                </List.Content>
                                <List.Content>
                                  <TwitterShareButton className="share-item" url={shareURL}>
                                    <TwitterIcon size={32} round={true} />
                                  </TwitterShareButton>
                                </List.Content>
                                <List.Content>
                                  <EmailShareButton className="share-item" url={shareURL}>
                                    <EmailIcon size={32} round={true} />
                                  </EmailShareButton>
                                </List.Content>
                                <List.Content>
                                  <LinkedinShareButton className="share-item" url={shareURL}>
                                    <LinkedinIcon size={32} round={true} />
                                  </LinkedinShareButton>
                                </List.Content>
                                <List.Content>
                                  <ViberShareButton className="share-item" url={shareURL}>
                                    <ViberIcon size={32} round={true} />
                                  </ViberShareButton>
                                </List.Content>

                                <List.Content>
                                  <TelegramShareButton className="share-item" url={shareURL}>
                                    <TelegramIcon size={32} round={true} />
                                  </TelegramShareButton>
                                </List.Content>

                                <List.Content>
                                  <WhatsappShareButton className="share-item" url={shareURL}>
                                    <WhatsappIcon size={32} round={true} />
                                  </WhatsappShareButton>
                                </List.Content>
                                <List.Content>
                                  <LineShareButton className="share-item" url={shareURL}>
                                    <LineIcon size={32} round={true} />
                                  </LineShareButton>
                                </List.Content>

                              </List>
                          </span>
                        </Button>

                        </Pane>
                      </Pane>
                    </Form.Field>
                  </Pane>
                </Pane>
                { userInfo.referenceUserId &&
                    <Pane className="row">
                    <Pane className="col-lg-12">
                      <Form.Field className="form-item">
                        <label htmlFor="email" className="form-label">Reference store </label>
                        <Pane className="row">
                          <Pane className="col-10">
                            <input
                              disabled
                              type="text"
                              className="form-input"
                              value={`${window.location.origin}/${userInfo.referenceUserId}`}
                            />
                          </Pane>
                          <Pane className="col-md-2">
                          <a className="ui button btn btn-default btn-url"
                            href={`${window.location.origin}/${userInfo.referenceUserId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            ><i className="fas fa-external-link-alt"></i></a>
                          </Pane>

                        </Pane>
                      </Form.Field>
                    </Pane>
                  </Pane>
                }

                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item">
                      <label htmlFor="email" className="form-label">
                        <FormattedMessage id="email" defaultMessage="Email" />
                      </label>
                      <input
                        type="email"
                        className="form-input"
                        id="email"
                        name="email"
                        value={data.email}
                        placeholder=""
                        disabled
                      />
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item">
                      <label htmlFor="full_name" className="form-label">
                        <FormattedMessage
                          id="full_name"
                          defaultMessage="Full Name"
                        />
                      </label>
                      <input
                        type="text"
                        className="form-input"
                        id="name"
                        name="name"
                        value={data.name}
                        placeholder=""
                        onChange={this.onChange}
                      />
                      {errors.name && <InlineMessage text={errors.name} />}
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item">
                      <label htmlFor="sub_title" className="form-label">
                        <FormattedMessage
                          id="sub_title"
                          defaultMessage="Short Title"
                        />
                      </label>
                      <input
                        maxLength={500}
                        type="text"
                        className="form-input"
                        id="sub_title"
                        name="sub_title"
                        value={data.sub_title}
                        placeholder=""
                        onChange={this.onChange}
                      />
                      {errors.sub_title && <InlineMessage text={errors.sub_title} />}
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item">
                      <label htmlFor="description" className="form-label">
                        <FormattedMessage
                          id="description"
                          defaultMessage="Description"
                        />
                      </label>
                      <textarea
                        maxLength={1000}
                        rows="4"
                        className="form-input"
                        id="description"
                        name="description"
                        value={data.description}
                        placeholder=""
                        onChange={this.onChange}
                      />
                      {errors.description && <InlineMessage text={errors.description} />}
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item">
                      <label htmlFor="my_website" className="form-label">
                        <FormattedMessage
                          id="my_website"
                          defaultMessage="My Website"
                        />
                      </label>
                      <input
                        type="text"
                        className="form-input"
                        id="url_website"
                        name="url_website"
                        value={data.url_website}
                        placeholder=""
                        onChange={this.onChange}
                      />
                      {errors.url_website && <InlineMessage text={errors.url_website} />}
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item">
                      <label htmlFor="email" className="form-label">
                        <FormattedMessage
                          id="phone"
                          defaultMessage="Phone number"
                        />
                      </label>
                      <PhoneInput
                        masks={{au: '.. .... ....'}}
                        country={"au"}
                        value={data.phone}
                        onChange={(phone) => this.onChangePhone(phone)}
                        style={{ width: "100%" }}
                        placeholder={intl.formatMessage({ id: "phone" })}
                      />
                      {errors.phone && <InlineMessage text={errors.phone} />}
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item">
                      <label htmlFor="email" className="form-label">
                        <FormattedMessage
                          id="address"
                          defaultMessage="Address"
                        />
                      </label>
                      <PlacesAutocomplete
                        value={data.address}
                        name="address"
                        onChange={this.onChangeAddress}
                        searchOptions={{
                          componentRestrictions: { country: "au" },
                        }}
                      >
                        {renderFunc}
                      </PlacesAutocomplete>
                      {errors.address && (
                        <InlineMessage text={errors.address} />
                      )}
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item">
                      <label htmlFor="email" className="form-label">
                        <FormattedMessage
                          id="post_code"
                          defaultMessage="Postcode"
                        />
                      </label>
                      <input
                        type="text"
                        className="form-input"
                        id="postCode"
                        name="postCode"
                        value={data.postCode}
                        placeholder=""
                        onChange={this.onChange}
                      />
                      {errors.postCode && (
                        <InlineMessage text={errors.postCode} />
                      )}
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row">
                  <Pane className="col-lg-12">
                    <Form.Field className="form-item">
                      <label htmlFor="country" className="form-label">
                        <FormattedMessage
                          id="country"
                          defaultMessage="Country"
                        />
                      </label>
                      <Pane className="d-flex">
                        <Pane className="d-flex align-items-center mr-5">
                          <Checkbox
                            className="mr-3"
                            radio
                            name="country"
                            value='aus'
                            checked={data.country === 'aus'}
                            onChange={this.onChangeRadio}
                          />
                          <img
                            src={
                              `${process.env.PUBLIC_URL
                              }/assets/images/flags/aus_flag.svg`
                            }
                            alt="store"
                            style={{ width: '40px' }}
                          />
                        </Pane>
                        <Pane className="d-flex align-items-center">
                          <Checkbox
                            className="mr-3"
                            radio
                            name="country"
                            value='vi'
                            checked={data.country === 'vi'}
                            onChange={this.onChangeRadio}
                          />
                          <img
                            src={
                              `${process.env.PUBLIC_URL
                              }/assets/images/flags/vi_flag.svg`
                            }
                            alt="store"
                            className="mr-2"
                            style={{ width: '40px' }}
                          />
                        </Pane>
                      </Pane>
                    </Form.Field>
                  </Pane>
                </Pane>
                <Pane className="row mt-2 mb-2">
                  <Pane className="col-lg-12">
                    <Radio
                      label={intl.formatMessage({ id: "edit_business_info" })}
                      toggle
                      onChange={this.toggle}
                      checked={this.state.showBusiness}
                    />
                    {isEmpty(userInfo.business.name) && (
                      <span id="desc-business"> (* You need to complete the business information to show your store)</span>
                    )}
                  </Pane>
                </Pane>
                {this.state.showBusiness ? (
                  <>
                    <h5 className="block-title">
                      <Icon name="building" />
                      <FormattedMessage
                        id="business_profile"
                        defaultMessage="Business profile"
                      />
                    </h5>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field className="form-item" error={!!errors.businessEmail}>
                          <label htmlFor="email" className="form-label">
                            <FormattedMessage
                              id="business_email"
                              defaultMessage="Business email"
                            />
                          </label>
                          <input
                            type="text"
                            id="businessEmail"
                            name="businessEmail"
                            className="form-input"
                            placeholder=""
                            value={data.businessEmail}
                            onChange={this.onChange}
                          />
                          {errors.businessEmail && (
                            <InlineMessage text={errors.businessEmail} />
                          )}
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field
                          className="form-item"
                          error={!!errors.businessName}
                        >
                          <label htmlFor="businessName" className="form-label">
                            <FormattedMessage
                              id="business_name"
                              defaultMessage="Business name"
                            />
                          </label>
                          <input
                            type="text"
                            id="businessName"
                            name="businessName"
                            className="form-input"
                            placeholder=""
                            value={data.businessName}
                            onChange={this.onChange}
                          />
                          {errors.businessName && (
                            <InlineMessage text={errors.businessName} />
                          )}
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field
                          className="form-item"
                          error={!!errors.businesWebsite}
                        >
                          <label htmlFor="businesWebsite" className="form-label">
                            <FormattedMessage
                              id="bussiness_website"
                              defaultMessage="Website"
                            />
                          </label>
                          <input
                            type="text"
                            id="businesWebsite"
                            name="businesWebsite"
                            className="form-input"
                            placeholder=""
                            value={data.businesWebsite}
                            onChange={this.onChange}
                          />
                          {errors.businesWebsite && (
                            <InlineMessage text={errors.businesWebsite} />
                          )}
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-6">
                        <Form.Field className="form-item" error={!!errors.businessState}>
                          <label htmlFor="email" className="form-label">
                            State
                          </label>
                          <Select
                            id="state"
                            name="state"
                            options={this.updatedStates('AU')}
                            value={data.businessState}
                            onChange={(value) => this.onChangeLocation('State', value ? value.isoCode : null)}
                          />
                        </Form.Field>
                      </Pane>
                      <Pane className="col-lg-6">
                        <Form.Field className="form-item" error={!!errors.businessCity}>
                          <label htmlFor="email" className="form-label">
                            City
                          </label>
                          <Select
                            id="city"
                            name="city"
                            options={this.updatedCities(data.businessState ? data.businessState : null)}
                            value={data.businessCity}
                            onChange={(value) => this.onChangeLocation('City', value ? value.name : null)}
                          />
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field
                          className="form-item"
                          error={!!errors.businessAddress}
                        >
                          <label
                            htmlFor="businessAddress"
                            className="form-label"
                          >
                            <FormattedMessage
                              id="business_address"
                              defaultMessage="Business address"
                            />
                          </label>
                          <PlacesAutocomplete
                            value={data.businessAddress}
                            name="businessAddress"
                            onChange={this.onChangeBusinessAddress}
                            searchOptions={{
                              componentRestrictions: { country: "au" },
                            }}
                          >
                            {renderFunc}
                          </PlacesAutocomplete>
                          {errors.businessAddress && (
                            <InlineMessage text={errors.businessAddress} />
                          )}
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field className="form-item" error={!!errors.type}>
                          <label
                            htmlFor="businessAddress"
                            className="form-label"
                          >
                            Type Business
                          </label>
                          <select
                            className="form-select"
                            name="format"
                            id="format"
                            value={this.state.data.type}
                            onChange={this.onChangeType}
                          >
                            {options.map((item) => {
                              return (
                                <option value={item.value} key={item.value}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                          {errors.type && <InlineMessage text={errors.type} />}
                        </Form.Field>
                      </Pane>
                    </Pane>
                    {this.state.categories.length ? (
                      <Pane className="row">
                        <Pane className="col-lg-12">
                          <Form.Field
                            className="form-item"
                            error={!!errors.category_id}
                          >
                            <label htmlFor="categoryId" className="form-label">
                              <FormattedMessage
                                id="category"
                                defaultMessage="Unit Price"
                              />
                            </label>
                            <TreeSelect
                              transitionName="rc-tree-select-dropdown-slide-up"
                              choiceTransitionName="rc-tree-select-selection__choice-zoom"
                              dropdownStyle={{
                                maxHeight: 300,
                                overflow: "auto",
                              }}
                              placeholder={<i>Select</i>}
                              searchPlaceholder="Search..."
                              treeDefaultExpandAll
                              treeData={this.nestPath(this.state.categories)}
                              treeNodeFilterProp="title"
                              onChange={this.onMultipleChange}
                              onSelect={this.onSelectTreeSelect}
                              value={this.state.data.category_id}
                              allowClear
                            />
                            {errors.category_id && (
                              <InlineMessage text={errors.category_id} />
                            )}
                          </Form.Field>
                        </Pane>
                      </Pane>
                    ) : null}
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field
                          className="form-item"
                          error={!!errors.businessPhone}
                        >
                          <label htmlFor="businessPhone" className="form-label">
                            <FormattedMessage
                              id="business_phone"
                              defaultMessage="Business phone"
                            />
                          </label>
                          <PhoneInput
                            masks={{au: '.. .... ....'}}
                            country={"au"}
                            value={data.businessPhone}
                            onChange={(phone) =>
                              this.onChangePhoneBusiness(phone)
                            }
                            style={{ width: "100%" }}
                            placeholder={intl.formatMessage({ id: "phone" })}
                          />
                          {errors.businessPhone && (
                            <InlineMessage text={errors.businessPhone} />
                          )}
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field className="form-item" error={!!errors.businessPostCode}>
                          <label
                            className="form-label"
                            htmlFor="businessPostCode"
                          >
                            <FormattedMessage
                              id="post_code"
                              defaultMessage="Postcode"
                            />
                          </label>
                          <input
                            type="text"
                            className="form-input"
                            id="businessPostCode"
                            name="businessPostCode"
                            value={data.businessPostCode}
                            placeholder=""
                            onChange={this.onChange}
                          />
                          {errors.businessPostCode && (
                            <InlineMessage text={errors.businessPostCode} />
                          )}
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field className="form-item">
                          <label htmlFor="businessSubTitle" className="form-label">
                            <FormattedMessage
                              id="businessSubTitle"
                              defaultMessage="Short Title"
                            />
                          </label>
                          <input
                            maxLength={500}
                            type="text"
                            className="form-input"
                            id="businessSubTitle"
                            name="businessSubTitle"
                            value={data.businessSubTitle}
                            placeholder=""
                            onChange={this.onChange}
                          />
                          {errors.businessSubTitle && <InlineMessage text={errors.businessSubTitle} />}
                        </Form.Field>
                      </Pane>
                      <Pane className="col-lg-12">
                        <Form.Field
                          className="form-item"
                          error={!!errors.businessDescription}
                        >
                          <label htmlFor="about" className="form-label">
                            <FormattedMessage
                              id="description"
                              defaultMessage="Description"
                            />
                          </label>
                          <textarea
                            maxLength={1000}
                            rows="4"
                            id="businessDescription"
                            name="businessDescription"
                            className="form-input"
                            placeholder=""
                            value={data.businessDescription}
                            onChange={this.onChange}
                          />
                          {errors.businessDescription && (
                            <InlineMessage text={errors.businessDescription} />
                          )}
                        </Form.Field>
                      </Pane>
                    </Pane>
                    <Pane className="row">
                      <Pane className="col-lg-12">
                        <Form.Field
                          error={!!errors.tradingHour}
                          className="form-item"
                        >
                          <label htmlFor="tradingHours" className="form-label">
                            <FormattedMessage
                              id="trading_hours"
                              defaultMessage="Trading hours"
                            />
                          </label>
                          <Pane className="trading-hours-list">
                            {Object.keys(
                              this.onSetOff(this.state.tradingHour)
                            ).map((key) => (
                              <TradingHourItem
                                key={key}
                                textMonth={key}
                                from={this.state.tradingHour[key].from}
                                to={this.state.tradingHour[key].to}
                                off={this.state.tradingHour[key].off}
                                onChangeHours={this.onChangeHours}
                                onChangeToggle={this.onChangeToggle}
                              />
                            ))}
                          </Pane>
                        </Form.Field>
                      </Pane>
                    </Pane>
                  </>
                ) : null}
              </Pane>
              <Pane className="row">
                <Pane className="col-lg-12 text-center">
                  <Button
                    type="submit"
                    className="btn btn-save mt-4"
                    disabled={loading}
                    loading={loading}
                  >
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </Button>
                </Pane>
              </Pane>
            </Pane>
          </Form>
        </Pane>
        <NotificationSystem ref="notificationSystem" />
      </Pane>
    );
  }
}

const bindStateToProps = (state) => ({
  userInfo: state.auth && state.auth.user,
  area: state.area
});

const bindDispatchToProps = (dispatch) => ({
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  updateUserInfo: bindActionCreators(updateInfo, dispatch),
  getMyBusinessInfo: bindActionCreators(getMyBusinessInfo, dispatch),
  updateMyBusinessInfo: bindActionCreators(updateMyBusinessInfo, dispatch),
  createMyBusinessInfo: bindActionCreators(createMyBusinessInfo, dispatch),
  updateAvatar: bindActionCreators(updateAvatar, dispatch),
  updateBackground: bindActionCreators(updateBackground, dispatch),
  changePassword: bindActionCreators(changePassword, dispatch),
  getProductCategories: bindActionCreators(getProductCategories, dispatch),
  getServiceCategories: bindActionCreators(getServiceCategories, dispatch),
  getFoodOrderCategories: bindActionCreators(getFoodOrderCategories, dispatch),
});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(Settings));
