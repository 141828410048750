const en = {
  // Common
  home: "Home",
  stores: "Stores",
  store: "Store",
  products: "Products",
  product: "Product",
  promotionalProducts: "PROMOTIONAL PRODUCTS",
  services: "Services",
  service: "Service",
  promotionalServices: "PROMOTIONAL SERVICES",
  contact: "Contact",
  policy: "Policy",
  details: "Details",
  policy_welcome: "Welcome to the Cardbey Policy",
  "terms-conditions": "Terms",
  terms: "Terms and Conditions",
  terms_welcome: "Welcome to the Cardbey Policy",
  faqs: "Faqs",
  fAQs: "FAQs",
  cities: "Cites",
  categories: "Categories",
  category: "Category",
  category_name: "Category Name",
  category_image: "Category Image",
  search: "Search",
  promotions: "Promotions",
  promotion: "Promotion",
  "home.promotions": "PROMOTIONS",
  name: "Name",
  full_name: "Full name",
  first_name: "First name",
  last_name: "Last name",
  phone: "Phone",
  email: "Email",
  password: "Password",
  confirm_password: "Confirm password",
  old_password: "Old password",
  new_password: "New password",
  confirm_new_password: "Confirm new password",
  address: "Address",
  post_code: "Postcode",
  position: "Position",
  description: "Description",
  sub_desc: "Sub Description",
  content: "Content",
  subject: "Subject",
  sign_in: "Sign In",
  forgot_password: "Forgot password?",
  reset_password: "Reset your password",
  website: "Website",
  sign_up: "Sign Up",
  trending: "Trending",
  "home.trending": "TRENDING",
  giftcards: "GIFTCARDS",
  best_picks: "BEST PICKS",
  picks: "PICKS",
  best_pick: "Best Picks",
  profiles_setting: "Settings",
  begin_the_bests_in_the_lits: "Being the bests in the list",
  newcoming: "NEW COMMING",
  just_listed: "JUST LISTED",
  classified_ad: "CLASSIFIED AD",
  food_order: "Food order",
  created_a_free_shop: "CREATE A FREE SHOP NOW",
  oppshop: "OPPSHOP",
  promotion_banner: "Promotion Banner",
  all: "All",
  this_is_your_site: "This is your site",
  start_now: "Start now",
  create_your_store: "CREATE YOUR STORE",
  create_for_free: "CREATE FOR FREE",
  testimonials: "TESTIMONIALS",
  best_matched: "Best Matched",
  people_bought_the_sam_your_alse_buying_these:
    "People bought the same yours also buying these",
  testimonials_carbey:
    "Cardbey is simple and user friendly. I have recommended many of my friends and family.Well done and keep up the good work",
  testimonials_carbey_kim: `Cardbey offers a wonderful suite of tools to help
  small businesses succeed in the ecommerce space.
  In 10 minutes I had my own online shop completed
  and set up ready to take orders. Amazing`,
  start_now_uppercase: "START NOW",
  your_online_shop: "Your Online Shop",
  your_online_shop_description:
    "Your beautiful online shop is here on our strong and powerful integrated e-commerce platform to help you unlock your huge potential and creation without limit to flourish your business",
  call_to_you: "Call To You",
  browse_by: "Browse by",
  product_category: "Product Category",
  service_category: "Service Category",
  featured: "Featured",
  image: "Image",
  product_name: "Product name",
  quantity: "Quantity",
  unit_price: "Unit price",
  total_price: "Total price",
  total_item: "Total Items:",
  total: "Total",
  avg_rating: "Avg. Rating:",
  customer_information: "Customer information",
  order_infomation: "ORDER INFOMATION",
  booking_infomation: "BOOKING INFOMATION",
  shipping_address: "Shipping address",
  ship_to_my_address: "Ship to my address",
  payment_method: "Payment method",
  payment_status: "Payment status",
  payment_information: "Payment information",
  order_number: "Order number",
  order_date: "Order date",
  total_payment: "Total payment",
  order_status: "Order status",
  delivery_address: "Delivery address",
  delivery_suburb: "Delivery suburb",
  delivery_postcode: "Delivery postCode",
  delivery_state: "Delivery state",
  delivery_country: "Delivery country",
  delivery_city: "Delivery city",
  delivery_method: "Delivery method",
  delivery_returns: "Delivery & Returns",
  view_all: "View All",
  proceed_to_check_out: "PROCEED TO CHECK OUT",
  custom_static_block_for_product_detail:
    "Custom static block for product detail",
  free_shipping: "FREE SHIPPING",
  free_shipping_on_all_us_order_or_order_above:
    "Free shipping on all US order or order above $200",
  support_24_7: "SUPPORT 24/7",
  contact_us_24_hours: "Contact us 24 hours a day. 7 days a week",
  "30_days_return": "30 DAYS RETURN",
  simply_return_it_within_30_days_for_an_exchange:
    "Simply return it within 30 days for an exchange.",
  payment_secure: "100% PAYMENT SECURE",
  we_ensure_secure_payment_with_pev: " We ensure secure payment with PEV",
  sign_up_newsletter: "SIGN UP NEWSLETTER",
  sign_up_our_newsletter_and_save_25_off_for_the_next_purchase:
    "Sign up our newsletter and save 25% off for the next purchase!",
  subscribe_to_get_your_15_off_first_order:
    "Subscribe to get your 15% off first order",
  subscirbe_to_our_newsletters: `Subscirbe to our newsletters and don't miss new arrivals, the latest fashion updates and our promotions.`,
  subscribe: `SUBSCRIBE`,
  your_information_will_never_be_shared_with_any_third_party:
    "Your information will never be shared with any third party.",
  dont_show_this_popup_again: `DON'T SHOW THIS POPUP AGAIN`,
  enter_your_email_address: "Enter your email address",
  pay_at_store: "Pay at store",
  pay_by_cash: "Pay by cash",
  pay_by_credit: "Pay by credit",
  pay_at_counter: "Pay at Counter",
  overview: "Overview",
  my_menu: "Menu",
  our_menu: "Menu",
  my_products: "Products",
  my_catalog: "Catalog",
  my_services: "Services",
  my_tables: "Tables",
  my_stores: "Stores",
  my_message: "Message",
  my_order: "Order",
  result_search: "Result Search",
  orders: "Orders",
  bookings: "Bookings",
  settings: "Settings",
  logout: "Logout",
  store_information: "Store Information",
  business_information: "Business Information",
  create_new_service: "Create a new service",
  create_new_collection: "Create a new collection",
  create_new_product: "Create new item",
  create_new_table: "Create a new table",
  create_new_video: "Create a new video",
  edit_product: "Edit product",
  edit_table: "Edit table",
  edit_service: "Edit Service",
  create_new_promotion: "Create a new promotion",
  create_now: "CREATE NOW",
  duration: "Duration",
  minutes: "Minutes",
  trading_hours: "Trading Hours",
  booking_times: "Booking times",
  note: "Note",
  create: "Create",
  from: "From",
  to: "To",
  expired: "Expired",
  confirmation: "Confirmation",
  in_promotion: "In promotion",
  start_date: "Start date",
  end_date: "End date",
  promotion_name: "Promotion name",
  percent_discount: "Percent discount",
  edit_promotion: "Edit promotion",
  buying_orders: "Buying orders",
  your_orders: "Your orders",
  selling_orders: "Selling orders",
  summary: "Summary",
  order_list: "Order List",
  your_name: "Your name",
  your_email: "Your email",
  your_phone: "Your phone",
  your_address: "Your address",
  customer_name: "Customer name",
  customer_email: "Customer email",
  customer_phone: "Customer phone",
  customer_address: "Customer address",
  refund_price: "Refund price",
  refunded: "Refunded",
  seller: "Seller",
  status: "Status",
  request_cancel: "Request cancel",
  cancel_request: "Cancel request",
  manage_booked_services: "Manage booked services",
  booking_management: "Booking Management",
  manage_your_services: "Manage your services",
  our_services: "Services",
  service_name: "Service name",
  service_provider: "Service provider",
  booking_detail: "Booking detail",
  booking_list: "Booking list",
  booking_date: "Booking date",
  appointment_date: "Appointment date",
  customer_appointment: "Customer Appointment",
  customer: "Customer",
  choose_date: "Choose date",
  time_slot: "Time slot",
  change_your_password: "Change your password",
  background_image: "Background image",
  edit_business_info: "Edit business info",
  off: "Off",
  your_order: "Your order",
  your_booking: "Your booking",
  time: "Time",
  reviews: "Reviews",
  your_comment: "Your comment",
  booking: "Booking",
  booking_information: "Booking information",
  booking_management: "Booking Management",
  found_for: "found for",
  result: "result",
  results: "results",
  create_new_menu: "Create new menu",
  frequently: "Frequently Asked Questions",
  frequently_welcome: "Welcome to the Cardbey Frequently Asked Questions",
  view_my_qr_code: "View my QR code",
  print_qr_code: "Print QR",
  was: "was",
  welcome_to_cardbey: "Welcome to Cardbey",
  you_have_had_a_online_store_click_to_view:
    "You have had an online store, click to view",
  view_your_store: "View your store",
  update_your_profile: "Update your profile",
  you_can_update_your_profile:
    "You can update your profile: Name, avatar, phone, address, your business profile ...",
  create_your_product_now: "Create your product now",
  create_your_serivce_now: "Create your service now",
  it_totaly_free: `It's totaly Free`,
  in_local_or_global_market: "In local or global market",
  start_your_online_store_in_minutes: "Start your online store in minutes",
  start_your_business_online_and_turn_it_mobile:
    "START YOUR BUSINESS ONLINE AND TURN IT MOBILE",
  cardbey_unlock_your_business: `Cardbey unlock your business power to sell any where to any one,
  locally or globally at your store or across the internet.
  Quickly and simply create on a single platform with order
  management, pricing and integrated marketing solution.`,
  sell_made_easy_now: "Sell made easy now.",
  // catalog
  our_catalog: "Our Catalog",
  // business
  business_profile: "Business profile",
  business_name: "Business name",
  business_email: "Business email",
  business_address: "Business address",
  business_phone: "Business phone",
  // action
  save: "Save",
  accept: "Accept",
  deny: "Deny",
  confirm: "Confirm",
  yes: "Yes",
  no: "No",
  edit: "Update",
  delete: "Delete",
  boost: "Boost",
  delivery: "Delivery",
  pickup: "Pickup",
  dine_in: "Dine In",
  take_away: "Take Away",
  remove: "Remove",
  view_more: "View more",
  send: "Send",
  back: "Back",
  add_to_cart: "Add to cart",
  book_now: "Book now",
  sort_by: "Sort by",
  share: "Share",
  go_back_to_home: "Go back to home",
  only_promotions: "Only promotions",
  not_promotions: "Not Promotions",
  learn_more: "LEARN MORE",
  more_infomation: "MORE INFOMATION",
  business_infomation: "BUSINESS INFOMARTION",
  custom_tab: "CUSTOM TAB",
  review: "Review",
  card_number: "Card number",
  about_us: "About Us",
  blogs: "Blogs",
  investor: "investor",
  partner_program: "Partner Program",
  contact_us: "Contact Us",
  cardbey: "Cardbey Shop",
  buy_sell: "BUY & SELL",
  helpfull: "HELPFULL",
  stay_conect_with_us: "STAY CONNECTED WITH US",
  help_password:
    "Please use 8 or more characters for your password. (Include: lowercase, uppercase and number).",
  videos: "Video",
  shows: "Shows",
  users_business_partners: "Users/Business Partners",
  instant_delivery: "instant delivery",
  // Navigation
  "nav.login": "Log in",
  "nav.shop_now": "Shop now",
  "nav.show_more": "Show more",
  "nav.build_a_shop": "Build a shop",
  // Search
  "search.im_looking_for": "I'm looking for",
  // validate
  "validate.require_field": "This field is required",
  "validate.special_field": "This field is not using special character",
  "validate.invalid_email": "Invalid email",
  "validate.invalid_post_code": "Invalid post code",
  "validate.invalid_price": "Invalid price",
  "validate.invalid_password": "Your password does not in the correct format",
  "validate.invalid_phone":
    "Your phone does not in the correct format, greater than or equal to 11 characters",
  "validate.invalid_confirm_password": "The confirm password doesn't match",
  "validate.invalid_compare_date":
    "The start date must be less than or equal the end date",
  "validate.invalid_card_number": "Card number is invalid",
  "validate.invalid_cvv": "CVV is invalid",
  "validate.please_select_a_payment_method": "Please select a payment method",
  "validate.choose_products_or_services":
    "Please choose your products or services",
  "validate.please_enter_your_reason": "Please enter your reason",
  "validate.please_choose_time_slots": "Please choose time slots",
  "validate.please_choose_staff_service": "Please choose staff service",
  "validate.please_choose_payment_method": "please choose payment method",
  "validate.date_expect_delivery":
    "Expect delivery time 50 mins higher than Pickup time",
  "validate.date_pickup_now": "Pickup time is 1 hour higher than current",
  "validate.invalid_credit_info": "Payment credit information invalid",
  // notification
  "notification.title": "NOTIFICATION",
  "notification.empty_bussiness":
    "Please complete your business information so your store and products will show on the website.  Thanks",
  "notification.please_try_again": "Please try again later!",
  "notification.please_login_to_make_like": "Please login to make likes!",
  "notification.please_login_to_make_comments":
    "Please login to make comments!",
  "notification.please_choose_rating": "Please rate before commenting. Thanks",
  "notification.thank_for_like": "Thank you for your like",
  "notification.require_character":
    "Your password must be at least 6 characters with a minimum of 1 lowercase, 1 uppercase character and a numeric character",
  "notification.you_must_login_before_you_can_continue":
    "You must login before you can continue!",
  "notification.your_account_has_been_created_successfully":
    "Your account has been created successfully",
  "notification.you_have_not_had_any_products_yet":
    "You haven't had any products yet",
  "notification.you_have_not_had_any_services_yet":
    "You haven't had any services yet",
  "notification.you_have_not_had_any_orders_yet":
    "You haven't had any orders yet",
  "notification.you_have_not_placed_any_orders_yet":
    "You haven't placed any orders yet",
  "notification.you_have_not_had_any_bookings_yet":
    "You haven't had any bookings yet",
  "notification.you_have_not_booked_any_services_yet":
    "You haven't booked any services yet.",
  "notification.you_have_not_had_any_promotions_yet":
    "You haven't had any promotions yet",
  "notification.this_store_has_not_had_any_products_yet":
    "This store hasn't had any products yet",
  "notification.this_store_has_not_had_any_services_yet":
    "This store hasn't had any services yet",
  "notification.this_store_has_not_had_any_promotions_yet":
    "This store hasn't had any promotions yet",
  "notification.you_have_not_had_any_digitals_share_yet":
    "You haven't had any digitals share yet",
  "notification.you_have_not_requested_any_digital_shares_yet":
    "You haven't requested any digital shares yet",
  "notification.are_you_sure_you_want_to_delete_this":
    "Are you sure you want to delete this",
  "notification.are_you_sure_you_want_to_cancel_this":
    "Are you sure you want to cancel this",
  "notification.are_you_sure_you_want_to_accept_this_request":
    "Are you sure you want to accept this request",
  "notification.are_you_sure_you_want_to_deny_this_request":
    "Are you sure you want to deny this request",
  "notification.are_you_sure_you_want_to_change_status":
    "Are you sure you want to change status",
  "notification.are_you_sure_you_want_to_create_staff_service":
    "Are you sure want to create staff service",
  "notification.your_request_has_been_denied_by_seller":
    "Your request has been denied by seller",
  "notification.successfully": "Successfully",
  "notification.select_your_products_and_services":
    "Select your products and services",
  "notification.are_you_sure_you_want_to_decline_this_request":
    "Are you sure you want to decline this request",
  our_products: "Products",
  our_video: "Video",
  "notification.cannot_get_order_details": "Cannot get order details",
  "notification.can_not_request_cancel_this_product":
    "Can't request cancel this product",
  "notification.you_have_sent_request": "You have sent request",
  "notification.you_have_sent_cancellation_request":
    "You have sent cancellation request",
  "notification.you_have_sent_request_successfully":
    "You have sent request successfully",
  "notification.you_have_cancelled_this_booking_successfully":
    "You have cancelled this booking successfully",
  "notification.your_password_has_been_changed_successfully":
    "Your password has been changed successfully",
  "notification.recommended_png_or_jpg_at_resolution":
    "Recommended PNG or JPG at 1280x760 resolution.",
  "notification.your_order_has_been_saved_successfully":
    "Your order has been saved successfully",
  "notification.your_booking_has_been_saved_successfully":
    "Your booking has been saved successfully",
  "notification.thank_for_review": "Thank you for your reviews",
  "notification.no_comments": "No comments",
  "notification.the_page_you_requested_was_not_found":
    "Oops! The page you requested was not found!",
  "notification.click_to_view_detail": "Click to view detail",
  "notification.this_item_is_in_cancellation_requesting":
    "This item is in cancellation requesting",
  "notification.forgot_password_success":
    "We will send password recovery instructions to your registered email. Please check in main mailbox and spam box.",
  "notification.you_have_entered_the_wrong_password":
    "You have entered the wrong password. Please log in again",
  "notification.you_have_successfully_signed_up_for_the_newsletter":
    "You have successfully subscribed to the newsletter",
  "notification.you_have_not_had_any_notifications_yet":
    "You haven't had any notifications yet",
  // Home page
  "home.managing_building_your_dream":
    "You're managing a business, building your dream one or thinking of Start-up.",
  "home.with_just_few_simple_steps":
    "With just few simple steps, you will unlock your huge potential with this robust, simple and efficient Cardbey tools to save cost, time, boost sale and keep strong connection with your customer",
  "home.cardbey_help_you_to_make_it_easier":
    "We all know business sometimes is hard so let Cardbey help you to make it easier",
  "home.every_second_a_new_thing_on_air_with_cardbey":
    "Every second, a new thing on air with Cardbey",
  "home.build_your_free_webstore_in_minutes":
    "Build your free webstore in minutes",
  "home.be_there_locally_or_globally_with_no_limits":
    "Be there locally or globally with no limits",
  "home.free_to_cut_cost_and_earn_thousands_more":
    "Free to cut cost and earn thousands more",
  "home.download_our_new_app": "Download Our New App",
  "home.let_bring_your_excellent_products":
    "Let’s bring your excellent products and services here.",
  "home.browse_by_product_category": "Browse by Product Category",
  "home.browse_by_service_category": "Browse by Service Category",
  "home.enjoy_exploring_or_picking_up":
    "Enjoy exploring or picking up things you need from these vast ranges of supplies.",
  "home.we_all_know_business_sometimes":
    "We all know business sometimes is hard so let Cardbey help you to make it easier.",
  "home.create_your_beautiful_free_online_store_in_minutes":
    "Create your beautiful free online store in minutes...",
  "home.yes_just_in_minutes":
    "Yes, Just in minutes, with some simple steps, you will get your business on air to start delivering values out to the world. Reaching an unlimited audience, exploring the infinitive opportunity. It’s Free.",
  "home.and_now_the_the_business_world_is_in_your_palm":
    "...and now, the business world is in your palm",
  "home.with_conscientious_advanced":
    "With conscientious & advanced solution helping to handle your business problems. An automated process integrated to generate your webstore into the mobile version and apps, it makes the business management easier than ever. The business now in your pocket.",
  "home.going_socialized": "Going socialized",
  "home.we_are_standing_out_with_millions":
    "We are standing out with millions on the social network channels to help you break the limits and reach out far beyond imagination.",
  "home.download_apps_here": "Download apps here",
  "home.we_are_here_on_app_stores": "We are here on app stores.",
  "home.let_go_mobile_new":
    "Let's go mobile new. Please go online to download Cardbey app to access your mobile version following these links below.",
  "home.book_your_service": "Book your service",
  "home.": "",
  // Sign in page
  "signin.do_not_have_an_account": "Don't have an account?",
  // Sign up page
  "signup.create_your_account": "Create your account, its free",
  "signup.agree_term_condition": "I have read and agree to the",
  "signup.term_condition": "Terms & Conditions",
  // Cart
  "cart.no_items_in_your_cart": "No items in your cart",
  "cart.continue": "Continue",
  "cart.continue_shopping": "Continue shopping",
  "cart.includes_gst_amount": "Includes GST Amount",
  "cart.proceed_to_checkout": "Proceed to checkout",
  "cart.your_shopping_cart_contains": "Your shopping cart contains",
  "cart.back_to_cart": "Back to cart",
  "cart.back_to_booking": "Back to booking",
  "cart.add_success": "The item added to your cart.",
  "cart.update_success" : "The item updated to your cart.",
  // Order
  "order.new": "New",
  "order.confirmed": "Confirmed",
  "order.inprogress": "In progress",
  "order.cancelled": "Cancelled",
  "order.preparing": "Preparing",
  "order.delivered": "Delivered",
  "order.done": "Done",
  "order.detail": "Order details",
  "order.cancel_requested": "Cancel requested",
  "order.requested_cancel": "Cancel requested",
  // Select
  select: "Select...",
  "select.category": "Select category",
  "select.category_add": "Add new category",
  "select.image": "Select a image",
  "select.image_add": "Add new image",
  // price
  "price.low_to_high": "Price: Low to High",
  "price.high_to_low": "Price: High to Low",
  // rating
  "rating.low_to_high": "Rating: Low to High",
  "rating.high_to_low": "Rating: High to Low",
  // pagination
  "pagination.previous": "previous",
  "pagination.next": "next",

  // group
  group: "Group",
  by_click_PAYMENT:
    "By click PAYMENT you agree to our privacy policy an term of server.You also agree to receive periodic email updates, discounts, and specical offers.",
  // video
  video_id_youtue: "2nsrtTvePrM",
  // FAQ
  "faq.template": `<h3><strong>1. Getting Started With Cardbey</strong></h3>
  <h4><strong>1.1 What is Cardbey and how does it work?</strong></h4>
  <p>Cardbey is a complete e-commerce platform that lets you start, grow, and manage a business.</p>
  <ul class="list-dot">
    <li>
      <p>Create and customize an online store</p>
    </li>
    <li>
      <p>Sell in multiple places, including web, mobile, social media, brick-and-mortar locations, and pop-up shops</p>
    </li>
    <li>
    <p>Manage products, payments, and delivery</p>
    </li>
  </ul>
  <p>With Cardbey you don’t have to worry about upgrading or maintaining software or web servers. This gives you the flexibility to access and run your business from anywhere with an internet connection.</p>
  <h4><strong>1.2 How much does Cardbey cost?</strong></h4>
  <p>Cardbey is a free platform for business owners, no credit card required. You can upgrade to premium account and become our Business Partner with a set-up fee of $150 and $30 monthly subscription and take advantage of all the benefits that come with a premium account. There is no contract, no tie-in. You can cancel anytime by giving us one-month’s notice via email at <a href="mailto:customersupport@cardbey.com">customersupport@cardbey.com</a> </p>
  <h4><strong>1.3 Do I need to be a designer or developer to set-up my store on Cardbey?</strong></h4>
  <p>No, you don’t need any coding or designing skill to create a store on Cardbey.
  Customize the look and feel of your store with your logo, photos and videos. If you need extra help, you can contact our customer team via Live Chat, or email us at <a href="mailto:customersupport@cardbey.com">customersupport@cardbey.com</a> </p>
  <h3><strong>2. Selling on Cardbey</strong></h3>
  <h4><strong>2.1 What do I need to start selling on Cardbey?</strong></h4>
  <p>To start selling on Cardbey, you’ll need to register for an account, select either a Member Account (FREE) or Premium Account (Fee) and products/service to sell.</p>
  <p>If you don’t have products/service to sell yet, sign-up as our Business Partner and earn 6.5% commission on sales by selling our products.</p>
  <h4><strong>2.2 Where can I sell with Cardbey?</strong></h4>
  <p>Cardbey lets you sell almost anywhere your customers are, including online, off-line, and social media.</p>
  <h4><strong>2.3 How do I create my store on Cardbey?</strong></h4>
  <ul class="list-dot">
    <li>
      <p>On Website</p>
      <p>
        Step 1: Go to <a href="https://cardbey.com/">https://cardbey.com/</a><br />
        Step 2: Choose "Sign Up"<br />
        Step 3: Fill-in information<br />
        Step 4: Receive confirmation email<br />
        Step 5: Sign-in and update your business details<br />
        Step 6: Add logo, products/services<br />
      </p>
    </li>
    <li>
      <p>On App</p>
      <p>
        Step 1: Get Cardbey app on <a href="https://apps.apple.com/vn/app/cardbey/id1289061763" target="_blank">iOS App Store</a>  or <a href="https://play.google.com/store/apps/details?id=com.cardbey.app&hl=en" target="_blank"> Google Play Store</a><br />
        Step 2: Click “Sign Up”<br />
        Step 3: Fill-in information
        Step 4: Fill in the store or business information
        Step 5: Post products and services you offer
      </p>
    </li>
  </ul>
  <p>If you need help, our customer support is available 24/7 via Live Chat, Email or Phone. Please check our  <a href="https://cardbey.com/contact-us" target="_blank"> Contact Us</a> page for details.</p>
  <h4><strong>2.4 How do I get my store featured on Cardbey?</strong></h4>
  <p>You can go to your Profile > Settings > Edit Business Info in order to enter or edit the basic information about your business. Please note that you need to enter your business information in order for your store to be visible on Cardbey.</p>
  <h4><strong>2.5 What happens when I receive an order?</strong></h4>
  <p>You’ll receive an email, mobile app notification, or RSS notification every time you receive an order.</p>
  <h3><strong>3. Payments</strong></h3>
  <h4><strong>3.1 How does Cardbey process payments?</strong></h4>
  <p>Cardbey uses third-party payment processor (also known as a payment provider or payment gateway). Cardbey is protected with SSL (Secure Socket Layer) security protocol to ensure sensitive information is safe.</p>
  <h4><strong>3.2 How do I receive my income?</strong></h4>
  <p>Cardbey processes payments minus our commission on Mondays weekly and transfer the rest to your registered bank account. You will be able to view your sales figure and other analytics in your admin page after you sign-in.</p>
  <h4><strong>3.3 Is there a cash payment option?</strong></h4>
  <p>Cardbey offers two payment methods "Pay by cash" and "Pay by card". Pay by cash option is only available for collection of products/services.</p>
  <h3><strong>4. Delivery</strong></h3>
  <h4><strong>4.1 Do I need to ship items myself?</strong></h4>
  <p>No, you don’t need to ship items yourself. You can use Cardbey Delivery Service on-demand to prepare and ship your orders.</p>
  <h4><strong>4.2 Can I pick up my product?</strong></h4>
  <p>Yes, Cardbey offers pick up option for products purchased on Cardbey online store. Make sure you choose pick up option in “Check Out”.</p>
  <h4><strong>4.3 How much does delivery cost?</strong></h4>
  <p>Cost varies according to size, weight and time of delivery.  Please go to Cardbey website for more information.</p>

  <h3><strong>5.Dispute Resolution</strong></h3>
  <h4><strong>5.1 I have a complaint, who do I contact?</strong></h4>
  <p>
  If you have any comments, complaints or any other problems please contact our customer support team by email at customersupport@cardbey.com. We aim to resolve any issues within 48 hours. If you are a customer and are not happy with the product/service you have purchased, please contact the business concerned direct and also inform us so we can chase this up for you. We are here to keep buyer/seller happy and aim to resolve any issues as soon as possible with a desired outcome for all involved.
  </p>
  <br /><br /><br />`,
  // Term Page
  "term.template": `
  <p>These terms and conditions (the "Terms") govern your access to and use of Cardbey's websites and mobile applications that link to or reference these Terms ("Site"). By accessing or using the Site, you are agreeing to these Terms and concluding a legally binding contract with Caterwin Company. Do not access or use the Site if you are unwilling or unable to be bound by the Terms.</p>

  <h3><strong>1. DEFINITIONS</strong></h3>
  <h4><strong>1.1 Parties</strong></h4>
  <p>"You" and "your" refer to you, as a user of the Site. A "user" is someone who accesses, browses, crawls, scrapes, or in any way uses the Site. "We," "us," and "our" refer to Cardbey.</p>
  <h4><strong>1.2 Website</strong></h4>
  <p>"Website" means our online stores, which can be accessed at (<a href="https://cardbey.com">https://cardbey.com</a>)</p>
  <h4><strong>1.3 Apps</strong></h4>
  <p>"App" means Cardbey online application software that is provided by Caterwin that you install or download from an online application store and access via a mobile device, including any smartphone and/or tablet device.</p>
  <h4><strong>1.4 Site</strong></h4>
  <p>"Site" means the Website and the App as mention above.</p>
  <h4><strong>1.5 Content</strong></h4>
  <p>"Content" means text, images, photos, audio, video, location data, and all other forms of data or communication. "Your Content" means Content that you submit or transmit to, through, or in connection with the Site, such as ratings, reviews, compliments, invitations, check-ins, messages, and information that you publicly display or displayed in your account profile. "User Content" means Content that users submit or transmit to, through, or in connection with the Site. "Cardbey Content" means Content that we create and make available in connection with the Site. "Third Party Content" means Content that originates from parties other than Cardbey or its users, which is made available in connection with the Site. "Site Content" means all of the Content that is made available in connection with the Site, including Your Content, User Content, Third Party Content, and Cardbey Content.</p>
  <h4><strong>1.6 Products</strong></h4>
  <p>"Products" means all products or services displayed for sale our website, including delivery services.</p>
  <h4><strong>1.7 Order</strong></h4>
  <p>"Order" means the collection of Items purchased by you from our website.</p>
  <h4><strong>1.8 Policies</strong></h4>
  <p>"Policies" means the various policies contained in or referred to herein and on our Website, including frequently asked questions ("FAQs") and online product information displayed, which form part of these Terms and Conditions and which may be updated from time to time without notice.</p>

  <h3><strong>2. CHANGES TO THE TERMS OF SERVICE</strong></h3>
  <p>We may modify the Terms from time to time. The most current version of these Terms will be located on the Site. You understand and agree that your access to or use of the Site is governed by the Terms effective at the time of your access to or use of the Site. If we make material changes to these Terms, we will notify you by email or by posting a notice on the Site prior to the effective date of the changes. We will also indicate at the top of this page the date that revisions were last made. You should revisit these Terms on a regular basis as revised versions will be binding on you. Any such modification will be effective upon our posting of new Terms. You understand and agree that your continued access to or use of the Site after the effective date of modifications to the Terms indicates your acceptance of the modifications.</p>

  <h3><strong>3. TRANSLATION</strong></h3>
  <p>We may translate these Terms into other languages for your convenience. Nevertheless, the English version governs your relationship with us, and any inconsistencies among the different versions will be resolved in favour of the English version.</p>

  <h3><strong>4. USING THE SITE</strong></h3>
  <h4><strong>4.1 Eligibility</strong></h4>
  <p>To access or use the Site, you must have the requisite power and authority to enter into these Terms. You may not access or use the Site if have previously banned you from the Site or closed your account.</p>

  <h3><strong>4.2 Permission to Use the Site</strong></h3>
  <p>We grant you permission to use the Site subject to the restrictions in these Terms. Your use of the Site is at your own risk, including the risk that you might be exposed to Content that is offensive, indecent, inaccurate, objectionable, or otherwise inappropriate.</p>

  <h3><strong>4.3 Site Availability</strong></h3>
  <p>The Site may be modified, updated, interrupted, suspended or discontinued at any time without notice or liability.</p>

  <h3><strong>4.4 User Accounts</strong></h3>
  <p>You are encouraged to create an account and provide certain information about yourself in order to use some of the features that are offered through the Site. You are responsible for maintaining the confidentiality of your account password. You are also responsible for all activities that occur in connection with your account. You agree to notify us immediately of any unauthorized use of your account. We reserve the right to close your account at any time for any or no reason.</p>
  <p>Your account is for your personal. In creating it, we ask that you provide complete and accurate information about yourself to bolster your credibility as a contributor to the Site. You may not impersonate someone else (e.g., adopt the identity of a celebrity or your next-door neighbour), create or use an account for anyone other than yourself, provide an email address other than your own, or create multiple accounts. If you use a pseudonym, take care to note that others may still be able to identify you if, for example, you include identifying information in your reviews, use the same account information on other sites, or allow other sites to share information about you with us. Please read our Privacy Policy for more information.</p>

  <h3><strong>4.5 Communications from Cardbey and other Users</strong></h3>
  <p>By creating an account, you agree to receive certain communications in connection with the Site. For example, you might receive compliments, ratings, messages etc. from other Users. You will also receive our weekly e-mail newsletter about happenings in your neighbourhood. You can opt-out of non-essential communications here.</p>

  <h3><strong>5. CONTENT</strong></h3>
  <h3><strong>5.1 Responsibility for Your Content</strong></h3>
  <p>You alone are responsible for Your Content, and once published, it cannot always be withdrawn. You assume all risks associated with Your Content, including anyone's reliance on its quality, accuracy, or reliability, or any disclosure by you of information in Your Content that makes you personally identifiable. You represent that you own, or have the necessary permissions to use and authorize the use of Your Content as described herein. You may not imply that Your Content is in any way sponsored or endorsed by Cardbey.</p>
  <p>You may expose yourself to liability if, for example, Your Content contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; or violates or advocates the violation of any law or regulation.</p>
  <h3><strong>5.2 Our Right to Use Your Content</strong></h3>
  <p>We may use Your Content in a number of different ways, including publicly displaying it, reformatting it, incorporating it into advertisements and other works, creating derivative works from it, promoting it, distributing it, and allowing others to do the same in connection with their own websites and media platforms. As such, you hereby irrevocably grant us world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use Your Content for any purpose. Please note that you also irrevocably grant the users of the Site and any Other Media the right to access Your Content in connection with their use of the Site and any media platforms. Finally, you irrevocably waive, and cause to be waived, against Cardbey and its users any claims and assertions of moral rights or attribution with respect to Your Content. By "use" we mean use, copy, publicly perform and display, reproduce, distribute, modify, translate, remove, analyze, commercialize, and prepare derivative works of Your Content.</p>
  <h3><strong>5.3 Ownership</strong></h3>
  <p>As between you and Cardbey, you own Your Content. We own the Cardbey Content, including but not limited to visual interfaces, interactive features, graphics, design, compilation, including, but not limited to, our compilation of User Content and other Site Content, computer code, products, software, aggregate user review ratings, and all other elements and components of the Site excluding Your Content, User Content and Third Party Content. We also own the copyrights, trademarks, service marks, trade names, and other intellectual and proprietary rights throughout the world ("IP Rights") associated with the Cardbey Content and the Site, which are protected by copyright, trade dress, patent, trademark laws and all other applicable intellectual and proprietary rights and laws. As such, you may not modify, reproduce, distribute, create derivative works or adaptations of, publicly display or in any way exploit any of the Cardbey Content in whole or in part except as expressly authorized by us. Except as expressly and unambiguously provided herein, we do not grant you any express or implied rights, and all rights in and to the Site and the Cardbey Content are retained by us.</p>
  <h3><strong>5.4 Advertising</strong></h3>
  <p>Cardbey and its licensees may publicly display advertisements and other information adjacent to or included with Your Content. You are not entitled to any compensation for such advertisements. The manner, mode and extent of such advertising are subject to change without specific notice to you.</p>
  <h3><strong>5.5 Other</strong></h3>
  <p>User Content (including any that may have been created by users employed or contracted by Cardbey) does not necessarily reflect the opinion of Cardbey. We reserve the right to remove, screen, edit, or reinstate User Content from time to time at our sole discretion for any reason or no reason, and without notice to you. For example, we may remove a review if we believe it violates our Content Guidelines. We have no obligation to retain or provide you with copies of Your Content, nor do we guarantee any confidentiality with respect to Your Content.</p>

  <h3><strong>6. GUIDELINE AND POLICIES</strong></h3>
  <h3><strong>6.1 Content Guidelines</strong></h3>
  <p>You represent that you have read and understood our Content Guidelines as below.</p>
  <h3><strong>i. General Guidelines</strong></h3>
  <p>Cardbey allows users to contribute different kinds of content, including reviews, photos, videos, events, votes, tips, direct messages, and more. Please also read the guidelines below for specific types of content that you might contribute to the site.</p>
  <ul class="list-dot">
    <li><p><strong>Inappropriate content:</strong> Threats, harassment, lewdness, hate speech, and other displays of bigotry are considered as inappropriate content. The use of those contents are strictly prohibited, Cardbey has the right to ban inappropriate content on the Site.</p></li>
    <li><p><strong>Conflicts of interest:</strong> Your contributions should be unbiased and objective. For example, you shouldn't write reviews of your own business or employer, your friends' or relatives' business, your peers or competitors in your industry, or businesses in your networking group. Business owners should not ask customers to write reviews.</p></li>
    <li><p><strong>Relevance:</strong> Please make sure your contributions are relevant and appropriate to the forum. For example, reviews aren't the place for rants about a business's employment practices, political ideologies, extraordinary circumstances, or other matters that don't address the core of the consumer experience.</p></li>
    <li><p><strong>Privacy:</strong> Don't publicize other people's private information. Please don't post close-up photos or videos of other patrons without their permission, and please don't post other people's full names unless you're referring to service providers who are commonly identified by or commonly share their own full names.</p></li>
    <li><p><strong>Intellectual property:</strong> Don't swipe content from other sites or users.</p></li>
  </ul>

  <h3><strong>ii. Additional Guidelines</strong></h3>
  <ul class="list-dot">
    <li>
      <p><strong>Review guidelines</strong></p>
      <ul class="list-dot">
        <li><p><strong>Personal experience:</strong> The review must come from the customer.</p></li>
        <li><p><strong>Accuracy:</strong> Make sure your review is factually correct. Feel free to air your opinions, but don't exaggerate or misrepresent your experience. We don't take sides when it comes to factual disputes, so we expect you to stand behind your review.</p></li>
        <li><p><strong>Review updates:</strong> Review updates should reflect a new experience or interaction with the business. If you'd like to add new insight to an old experience, just edit your review instead of creating a new update.</p></li>
      </ul>
    </li>
    <li>
      <p><strong>Photo and Video Guidelines</strong></p>
      <ul class="list-dot">
        <li><p>Business photos and videos should be broadly relevant to the business and reflect the typical consumer experience (e.g., what the business looks like, what the business offers, etc.).</p></li>
        <li><p>If you post a photo or video that showcases a more unique personal experience with a business (e.g., your smiling group of friends at the bar, the fly in your soup), we may remove it from the photo gallery for the business, but it still may appear alongside your review if you write one about the business.</p></li>
      </ul>
    </li>
    <li>
      <p><strong>User Profile Guidelines</strong></p>
      <ul class="list-dot">
        <li><p>We encourage you to use your account profile to let people know who you are and your business partners or your friend can find you easily. Users want to read reviews from people they know and trust (not those with profiles that are empty or laced with inappropriate content).</p></li>
      </ul>
    </li>
    <li>
      <p><strong>Messaging Guidelines</strong></p>
      <ul class="list-dot">
        <li><p>Cardbey has a handy messaging feature to keep you connected with your friends and business partners. First off, please don&#39;t use this feature to harass other users or send other inappropriate content. Second, user accounts are for personal, non-commercial use only.</p></li>
      </ul>
    </li>
    <li>
      <p><strong>Shop Owner Guidelines</strong></p>
      <ul class="list-dot">
        <li><p><strong>Public Comments:</strong> Shop owners can post a public comment. As with direct messaging, we ask that business owners upload a clear photo of themselves to help personalize their message. Don't use public comments to launch personal attacks, advertise, or offer an incentive to change a review.</p></li>
        <li><p><strong>Video and Photos:</strong> Videos are a great way for advertisers to promote themselves on Cardbey. Be as creative as you can, but those should be under Cardbey guidelines.</p></li>
      </ul>
    </li>
  </ul>

  <h3><strong>6.2 Privacy</strong></h3>
  <p>You represent that you have read and understood our Privacy Policy. Note that we may disclose information about you to third parties if we have a good faith belief that such a disclosure is reasonably necessary to</p>
  <ul class="list-dot">
    <li>Take action regarding suspected illegal activities;</li>
    <li>Enforce or apply our Terms and Privacy Policy;</li>
    <li>Comply with legal process or other government inquiry, such as a search warrant, subpoena, statute, judicial proceeding, or other legal process served on us; or</li>
    <li>Protect our rights, reputation, and property, or that of our users, affiliates, or the public.</li>
  </ul>
  <p>We have the right to sync the contact on your phone with your profile on App. We have the right to send invite message and relevant information to your contact under your name with your approval.</p>

  <h3><strong>6.3 Copyright And Trademark Disputes</strong></h3>
  <p>We do not take kindly to those who abuse the intellectual property rights of others. If you believe that your copyright or trademark is being infringed on the Site, please send us a written notice with the following information:</p>
  <ul class="list-dot">
    <li>Identification of the copyrighted or trademarked work that you claim has been infringed;</li>
    <li>Identification of the allegedly infringing content, and information reasonably sufficient to permit Cardbey to locate it on the Site (e.g., the URL for the web page on which the content appears);</li>
    <li>A statement by you that you have a good faith belief that the use of the content identified in your notice in the manner complained of is not authorized by the copyright/trademark owner, its agent, or the law;</li>
    <li>A statement by you that you attest, under penalty of perjury, that the information in your notice is accurate and that you are the copyright/trademark owner or authorized to act on the owner's behalf; and</li>
    <li>Your physical or electronic signature, together with your contact information (address, telephone number and, if available, email address).</li>
  </ul>
  <p>At the same time, we do not take kindly to those who abuse the scope of their own intellectual property rights. If you believe that your content should not have been removed for alleged copyright or trademark infringement, you may send us a written counter-notice with the following information:</p>

  <ul class="list-dot">
    <li>Identification of the copyrighted or trademarked work that was removed, and the location on the Site where it would have been found prior to its removal;</li>
    <li>A statement, under penalty of perjury, that you have a good faith belief that the content was removed as a result of a mistake or misidentification. For trademark disputes only: information reasonably sufficient to explain why you believe you are not infringing the trademarked work;</li>
    <li>A statement that you consent to the jurisdiction. Please also include a statement that you will accept service of process from the person who sent the original infringement notice to Cardbey, or an agent of such person;</li>
    <li>Your physical or electronic signature, together with your contact information (address, telephone number and, if available, email address).</li>
  </ul>

  <p>We will respond to all such notices and comply with applicable law. We reserve the right to remove content alleged to be infringing without prior notice and at our sole discretion. We also reserve the right to terminate a user's account if the user is determined to be a repeat infringer.</p>
  <p>You can send us your copyright or trademark notices to address that stated on the Site.</p>

  <h3><strong>7. PURCHASES AND STOCK AVAILABILITY</strong></h3>
  <p>You will be required to provide certain information, in order to purchase Products that are displayed on our website, which may including personal information. Please refer to our Privacy Policy to view how we process and use your personal information.Prices displayed on our Site are subject to change without notice. Prices will include tax, where this is required by specific country legislation.</p>
  <p>Orders placed may be declined or cancelled by us in the event of Products being out of stock, damaged Products, incorrect Product information, incorrectly displayed prices, and due to payment for Products not being received or payments not being authorized by Cardbey.</p>
  <p>We will notify you as soon as we can if any Products Ordered are not available.</p>
  <p>Once you have selected your payment method, you will be directed to a link to a secure site for payment of the applicable purchase price for the Products.</p>

  <h3><strong>8. PAYMENT OPTIONS</strong></h3>
  <p>Payment is subject at all times to Cardbey's agreements with its bankers and any third party financial transactions service provider.</p>
  <p>You may pay for your online Orders and delivery costs using various payment options, dependent on the country you select on our Site, including where available:</p>
  <ul class="list-dot">
    <li>
      <p>Credit or debit card:</p>
      <ul class="list-dot">
        <li>We subscribes to high security and encryption standards allowing all customers to shop with peace of mind. We have taken all reasonable security measures to ensure that payment and card details, as well as related confidential information, cannot be accessed by anyone who is not authorized to do so.</li>
        <li>When placing your order, and proving the required personal details, including your payment card details you warrant that you are authorised to make payment with the payment card and that there are enough funds available to pay for your order.</li>
      </ul>
    </li>
    <li><p>Paypal (refer to Paypal's terms and conditions, which you will be bound by when using this payment method, Legal Agreements for PayPal Services)</p></li>
    <li>
      <p>Cash</p>
      <ul class="list-dot">
        <li><p>Cash payment only applicable for in shop pick up only.</p></li>
      </ul>
    </li>
  </ul>
  <p>Once your payment has been received you will be issued with an electronic acknowledgement of payment via Cardbey Site.</p>

  <h3><strong>9. DELIVERY AND RETURNS</strong></h3>
  <h4><strong>9.1 Deliveries and Collection</strong></h4>
  <p>Order is delivered to you using service of Third Party Partners. Carbey is indemnity from any lost, damage or other problems that might occur during delivery.</p>
  <p>Order can be delivered to you via courier or you may elect to collect your Order from stores.</p>
  <p>Delivery fees vary depending on your selected delivery option and Products ordered, and will be displayed during the ordering checkout process, but before checkout has been finalized.</p>
  <p>We will notify you via notification on Site as to the progress of your Order.</p>
  <h4><strong>9.2 Returns</strong></h4>
  <p>Returns policies may vary depend on the shop you purchase the Products. Please visit shop web page or contact shop owner for detailed information.</p>

  <h3><strong>10. CANCELLATIONS</strong></h3>
  <p>You may cancel the Order at any stage prior confirmation. After confirmation, cancellation is negotiable between shop owner and users.</p>

  <h3><strong>11. WARRANTY</strong></h3>
  <p>We shall not be liable for any action or claims for injury or damages of whatever nature arising from or in connection with use of the Products in breach of these T&amp;Cs.</p>

  <h3><strong>12. LIMITATION OF LIABILITY</strong></h3>
  <p>Whilst every attempt is taken by Cardbey to ensure your security when making use of the Site, due to the nature of the Internet we are unable to guarantee that any products and services or any websites accessible via the Website will be uninterrupted or virus or error-free. We therefore caution you to check all emails, attachments and files before downloading them.</p>
  <p>We may provide links to other websites, social media sites or resources. We are unable to accept, and do not accept, responsibility for these websites, social media sites or resources; nor have we endorsed their content, products or services merely because they are accessible via the Website.</p>
  <p>While we make all reasonable efforts to ensure that all information provided by us in connection with the Website is accurate at the time of its inclusion on the Website, you acknowledge and understand that there may be errors, including incorrect prices, inaccuracies or omissions in respect of which we exclude all liability. We make no representations, guarantees or warranties of any nature whatsoever concerning the information included on our Website and web pages (including, but not limited to, links to third parties' web pages). You shall be solely responsible for any decisions or actions you take based on the information contained on such web pages.</p>
  <p>While we take all reasonable steps to safeguard the security of any information you input or send to us in connection with the Cardbey Site, by using secure services and encryption technology where we deem appropriate, we accept no responsibility or liability whatsoever, as far as the law allows, for any damages that you may suffer as a result of the breach of the confidentiality of such information.</p>
  <p>You hereby agree, to as far as the law allows, to indemnify Cardbey against any loss, claim or damage suffered, or liability sustained by your or any third party acting for you, due to your or their use of our Site and/or any thirty party websites linked to our website.</p>
  <p>We shall not be liable to you in contract, delict (including for negligence) or otherwise:</p>
  <ul class="list-dot">
    <li><p>For any amount in respect of any damage or loss arising from the consequences of your use of the Website, viruses received by you via the Site or of our failure to provide the Site in accordance with this Agreement; or</p></li>
    <li><p>For any economic losses or damages, any indirect, special or consequential loss (including (without limitation) loss of data, goodwill or reputation or any wasted expenditure) including but not limited to losses arising from your use of the Website or through any viruses; or</p></li>
    <li><p>For any failure to perform our obligations or failure to perform our obligations properly as a result of our being prevented from doing so by an event beyond our reasonable control (which may include, without limitation, strikes; labour disputes; acts of God; war; riot; civil action; malicious acts or damage; compliance with any law, governmental or regulatory order, rule, regulation or direction; any act or omission of any government or other competent authority; accident; equipment or services failure, including the unavailability of third party telecommunications services, lines or other equipment; the failure of third party suppliers to comply with their obligations to us; fire; flood or storm).</p></li>
  </ul>

  <p>Each provision of this clause operates separately. If any part is held by a court to become unenforceable due to voidness, invalidity, illegality or unlawfulness, then the other parts shall be severable and shall still apply in their entirety.</p>

  <h3><strong>13. BREACH</strong></h3>
  <p>Should you breach and of these T&amp;Cs we reserve the right to cancel any Order and/or your website registration, notwithstanding our rights to exercise other legal remedies available to us.</p>

  <br /><br /><br />
  `,
  // page policy
  "policy.template": `
  <h3><strong>User Privacy Notice</strong></h3>
  <p>Cardbey knows that you care how your information is used and shared, so keeping the information you trust us with safe is one of our top priorities.</p><p>Our Privacy Notice details what information we collect from our users, and how we use it to improve your experience on Cardbey. You’ll find the full details below:</p>
  <p><strong>What personal information about customers does Cardbey collect?</strong></p>
  <p>When you sign up with us, we gather your name and contact details. When you buy something, we ask for payment details and delivery address.</p>
  <p>If you’re a seller, we ask you to provide your business name and business details.</p>
  <p>We also collect some information automatically such as cookies and what device you’re using to view our site.</p>
  <p>When you download or uses our applications on Apple Store or Google Store, we may receive your location and your contact list if you allow us to do that.</p>
  <p><strong>How does Cardbey use personal information?</strong></p>
  <p>The information we learn from customers helps us personalize and continually improve your Cardbey experience by suggesting items we think you’d like based on what you’ve bought before, for example.</p>
  <p>We also use your information to help sellers do advertising or marketing efficiently.</p>
  <p><strong>Does Cardbey share the information it shared?</strong></p>
  <p>The personal information is an important part of our business. We only share your personal information with other Cardbey members to facilitate transactions and to allow them to contact you completed a transaction together.</p>
  <p>We may share information with third-party service providers to perform their functions. Examples include delivering packages, processing credit card payments and proving customer service.</p>
  <p><strong>How secure is information?</strong></p>
  <p>We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you input.</p>
  <h3><strong>User Agreements</strong></h3>
  <h4><strong>Selling Policy</strong></h4>
  <p>As a seller, you must:</p>
  <ul class="list-dot">
    <li><p>Provide clearly your products or services in detail to buyer</p></li>
    <li><p>Make sure the item is delivered to buyer.</p></li>
    <li><p>Update the item’s status via Cardbey so that buyer could keep track easily</p></li>
    <li><p>Specific your return policy to buyer</p></li>
    <li><p>Take responsibility for contacting customers and deliver products to customers on time. And seller must update status on our system.</p></li>
  </ul><p>We do not allow seller to deliver flawed item or item with bad condition to buyer. If happening, buyer has right to report this seller to us and we may block this account on Cardbey system.</p>
  <p>Seller must follow the return policy to make refund to buyer.</p>
  <p>For cancel request from buyer, we encourage seller to communicate directly with buyer before making decision. Seller should specific reason to buyer if seller deny the buyer’s cancel request.</p>
  <h4><strong>Buying Policy</strong></h4>
  <p>As a buyer, you can order any products from many stores or book any service on our system.</p>
  <p>We want all Cardbey users to be able to buy or sell on Cardbey with confidence. We strive to protect sellers from unfair and unsafe buyer activity. We don’t allow buyers with the following behaviours:</p>
  <ul>
    <li><p>Not paying for items</p></li>
    <li><p>Misusing returns:</p><p>We encourage all sellers to specify a clear return policy when opening a store via Cardbey. If you want to return an item, please review the seller’s return policy before initiating a return.</p>
      <ul class="list-dot">
        <li>
          <p>Allow:</p>
          <ul class="list-dot">
            <li><p>Returning item based on Australian consumer law</p></li>
            <li><p>Returning an item within the terms of the seller’s return policy</p></li>
          </ul>
        </li>
        <li>
          <p>Not Allow:</p>
          <ul class="list-dot">
            <li><p>Returning a different item</p></li>
            <li><p>Not returning the item in the condition that it was sent to you</p></li>
            <li><p>Returning an item that has been damaged after being delivered</p></li>
            <li><p>Not following the terms of the seller’s return policy</p></li>
          </ul>
        </li>
      </ul>
    </li>
    <li><p>Make sure the item is delivered to buyer.</p></li>
    <li><p>Update the item’s status via Cardbey so that buyer could keep track easily</p></li>
    <li><p>Specific your return policy to buyer</p></li>
  </ul>

  <h4><strong>Cancel and Refund Policy</strong></h4>
  <h5><strong>Cancellation</strong></h5>
  <p>As a buyer, you could be allowed to send Cancel Request to seller for your ordered items via Cardbey if they are not delivered to you by seller.</p>
  <p>As a seller, when you receive a request for cancel from buyer, we encourage you to contact directly with buyer to confirm the reason for cancellation. Depending on the characteristics or conditions of item, you may accept or deny buyer’s cancel request. However, please make sure that customers understand your decision clearly.</p>
  <h5><strong>Refund</strong></h5><p>If the cancelled Order is paid via Cardbey system, we will take responsibility to make refund for buyer within 15 business days.</p>
  <p>If the order has been delivered to buyer by seller, the seller will take responsibility to refund for returned items based on Australian consumer law and the seller’s return policy.</p>
  <h4><strong>Delivery Policy</strong></h4>
  <p>Now Cardbey does not support deliver products or services. Sellers must take responsibility for delivery process. We recommend that sellers should contact buyer to confirm information before delivering items. Sellers must update the Ordered Item’s status on Cardbey so that buyers could keep track their order easily.</p>
  <br /><br /><br />
  `,
  // ABOUT US
  "about_us.template": `<h4><strong>Cardbey is the global marketplace with local support</strong></h4>
  <p>Cardbey is the new platform tailored for regions, providing customers with an easy, secure, and convenient online shopping experience through strong local support. We connect thousands of businesses and users in an integrated eCommerce platform which encompasses online and offline media.</p>
  <h4><strong>Our mission - Making commerce better for everyone</strong></h4>
  <p>Cardbey was founded in 2015 in Melbourne, Australia with the mission of lowering costs, removing barriers and enhancing online buying/selling experience. This is done locally and across border to provide consumers with the best value products and services on the market. We do this whilst also being a socially and ethically responsible company that contributes to sustainability as we believe the future can only be possible by taking care of our planet. </p>
  <h4><strong>Our purpose</strong></h4>
  <p>We believe in the transformative power of technology to allow everyone to start, run, and grow their own business. We work to connect buyers and sellers within one strong community.</p>
  <h4><strong>Our community</strong></h4>
  <p>We work to serve SMEs all over the world. Practically anybody who has products/services to sell and who are interested in reaching new and more customers. Customer satisfaction is of paramount importance to Cardbey and we always aim for the highest customer satisfaction.  </p>
  <h4><strong>Choose Cardbey</strong></h4>
  <p>We are continually changing and adapting to the market and technology and strive to be a market leader in the online marketplace.  We are constantly adding new features, tools, functions, and services so follow us on social media to stay up-to-date.</p>
  <h4><strong>Testimonials</strong></h4>
  `,
  stay_connected_widt_us: "Staying connected with us.",
  cardbey_has_helped_me_increase:
    "Cardbey has helped me increase my sales by 25%. I am very pleased with the result and have recommended Cardbey to my friends and family.",
  cardbey_is_a_complete_product:
    "Cardbey is a complete product, offering a bustling marketplace, an advertising platform, and a home delivery service. I couldn’t believe the amount of values packed into just one Partner Account.",
  cardbey_is_in_a_class:
    "Cardbey is in a class of its own when it comes to e-commerce. I got started easily and delivered meals since week one. Very friendly drivers and customer support. A truly frictionless system",
  // animation
  "animmation-mac": "/assets/images/gallery/animation-mac-en.gif",
  "animation-phone": "/assets/images/gallery/mobile&phone.gif",
  "animation-phone-straight": "/assets/images/gallery/phone.gif",
  cardbey_is_the_global_marketplace_with_local_support:
    "Cardbey is the global marketplace with local support",
  cardbey_is_the_new_global_platform_tailored_for_regions: `Cardbey is the new global platform tailored for regions, providing customers with an easy, secure, and convenient online shopping experience through strong local support. We connect thousands of businesses and users in an integrated eCommerce platform which encompasses online and offline media.`,
  our_mission: "Our mission",
  making_commerce_better_for_everyone: "Making commerce better for everyone",
  cardbey_was_founded: `Cardbey was founded in 2015 in Melbourne, Australia with the mission of lowering costs, removing barriers and enhancing online buying/selling experience. This is done locally and across border to provide consumers with the best value products and services on the market. We do this whilst also being a socially and ethically responsible company that contributes to sustainability as we believe the future can only be possible by taking care of our planet. `,
  our_purpose: "OUR PURPOSE",
  we_believe_in_the_transformative: `We believe in the transformative power of technology to allow everyone to start, run, and grow their own business. We work to connect buyers and sellers within one strong community.`,
  our_community: "Our community",
  we_work_to_serve_smes:
    "We work to serve SMEs all over the world. Practically anybody who has products/services to sell and who are interested in reaching new and more customers. Customer satisfaction is of paramount importance to Cardbey and we always aim for the highest customer satisfaction.  ",
  choose_cardbey: "Choose Cardbey",
  we_are_continually_changing_and_adapting:
    "We are continuously changing and adapting to the market and technology and strive to be a market leader in the online marketplace. We are constantly adding new features, tools, functions, and services. Follow us on social media to stay up-to-date.",
  sell_online_with_cardbey: "Sell online with Cardbey",
  the_simpler_way_to_build_your_online:
    "The simpler way to build your online store for free, forever!",
  get_started_for_free: " Get started for free",
  go_to_stores: "Go to stores",
  try_cardbey_at_no_risk:
    "Try Cardbey at no risk, no credit card required. By entering your email, you agree to receive marketing emails from Cardbey.",
  get_your_store_ready_in_3_minutes: "Get your store ready in 3 minutes",
  whether_you_selling_physical:
    "Whether you’re selling physical products or providing services, Cardbey’s got you covered. Start selling anywhere for free.",
  cheaper_simpler_home_delivery_service:
    "Cheaper, simpler Home Delivery Service",
  one_click_shipping_availabel_at_checkout_for_your_customers:
    "One-click shipping available at checkout for your customers, shipped by Cardbey. No need for third-party delivery.",
  all_in_one_service_for_store_owners: "All-in-one service for store owners",
  widen_your_reach:
    "Widen your reach, reduce marketing effort with Cardbey Classified Ads",
  build_your_online_store_no_matter_what_business_your_in:
    "Build your online store, no matter what business you’re in.",
  food_drink: "FOOD & DRINK",
  spa_beauty: "SPA & BEAUTY",
  handyman_service: "HANDYMAN SERVICE",
  import_export_goods: "IMPORT & EXPORT GOODS",
  choose_a_path_forward: "Choose a path forward.",
  start_your_own_business: "Start your own business",
  bring_your_business_ideas_to_life_at_no_cost:
    "Bring your business ideas to life at no cost. More time, more budget to validate and explore your ideas.",
  move_your_business_online: "Move your business online",
  got_your_own_brick_and_mortar_store:
    "Got your own brick-and-mortar store? It’s time to reach more customers and serve them better anywhere, anytime.",
  switch_to_cardbey: "Switch to Cardbey",
  no_matter_what_platform_you_using:
    "No matter what platform you’re using, it’s easy to expand your customer base by switching to Cardbey.",
  with_you_wherever_you_going: "With you wherever you’re going",
  sell_deliver_your_products_everywhere:
    "Sell & deliver your products everywhere",
  use_one_platform_to_sell_to_anyone:
    "Use one platform to sell to anyone, anywhere. Deliver orders with no hassle and no middleman.",
  explore_ways_to_sell: "Explore ways to sell",
  get_booked_on_the_go: "Get booked on the go",
  whether_you_an_agency:
    "Whether you’re an agency, a restaurant, a beauty parlor or other services, start getting more international & local bookings with just one online store.",
  explore_ways_to_get_bookings: "Explore ways to get bookings",
  let_get_social: "Let’s get social",
  connect_with_customers_and_businesses_to_make_buying:
    "Connect with customers and businesses to make buying/selling experience more enjoyable",
  stay_connected: "Stay connected",
  get_help_at_every_step_of_the_way: "Get help at every step of the way",
  cardbey_support: "Cardbey Support",
  contact_support_24_7:
    "Contact support 24/7, whether you’re troubleshooting issues or seeking business advice",
  contact_support: "Contact support",
  start_your_business_journey_with_cardbey:
    "Start your business journey with Cardbey",
  explore_the_tools_and_services_that_help_you_start:
    "Explore the tools and services that help you start, run, and grow your business",
  create_your_online_business_for_free: "Create your online business for free",
  add_to_order: "ADD TO ORDER",
  choose_items_to_add_in_your_order: "Choose items to add in your order",
  delivery_service: "Delivery Service",
  delivery_now: "Delivery Now",
  we_want_to_give_you:
    "We want to give YOU a 20% DISCOUNT for your first order",
  wait: "Wait!",
  return_to_store: "Return to store",
  digital_slideshow: "Digital Slideshow",
  digitals_share: "My Requested Share",
  screen_share_requested: "Screen share requested",
  your_requested: "Your requested",
  create_new_digital: "Create a new digital",
  create_new_screen: "Create a new screen",
  cover_image: "Cover Image",
  title: "Title",
  image_horizontal: "Image Horizontal",
  image_vertial: "Image Vertical",
  my_contact: "My Contacts",
  "notification.contact.add": "Congrat! Contact successfully added.",
  "notification.contact.delete": "Contact removed.",
  qr_code_shows: "Shows",
  qr_code_map: "Map",
  qr_code_call: "Call",
  qr_code_share: "Share",
  choose_list_text: "List View (none pictures)",
  choose_grid_text: "Grid View (have pictures)",
  confirm_show_items_type: "Are you sure to want to change show items type?",
  confirm_account_type: "Are you sure to want to change account type?",
  confirm_hide_review: "Are you sure to want to hide this review?",
  create_business: "Create business account",
  create_business_content:
    "You need to complete the business information to show your store",
  switch_account: "Switch to Business Account",
  switch_account_content: "Switch to Business Account to show your store",
  my_collections: "Collections",
  images: "Image",
  posts: "Post",
  users: "Users",
  switch_normal_account: "Switch to normal account",
  switch_business_account: "Switch to business account",
  menu_store_food: "ORDER NOW",
  menu_store_service: "BOOK NOW",
  menu_store_product: "BUY NOW",
  store_order: "STORE'S ORDER",
  store_booking: "STORE'S BOOKING",
  view_cart: "VIEW CART",
  bussiness_website: "Website",
  subTitle: "Short title",
  claim_your_business: "Claim your business",
  claim_success_message:
    "Thank you for submitting your claim, We will contact you soon!",
  country: "Country",
  post: "Post",
  already_account: "Already have an account? \n Login.",
  back_to_store: "Back to Store",
  inventory_name: "In stock",
  inventory_service_name: "Available",
  option_group: "Group Option",
  option_group_name: "Name",
  option_group_type: "Type",
  option_children_name: "Name",
  option_children_price: "Price",
  food: "Food",
  project_list: "Project List",
  task_list: "Task List",
  create_new_project: "Create New Project",
  edit_project: "Edit Project",
  create_new_task: "Create New Task",
  edit_task: "Edit Task",
  projects: "Projects",
  staffs: "Staffs",
  tasks: "Tasks",
  select_project: "Select Project",
  enter_your_name_task: "Enter your name task",
  enter_your_description_task: "Enter your description task",
  task_status: "Task Status",
};

export default en;
