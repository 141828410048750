import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LoadingBar from "react-redux-loading-bar";
import { Wrapper, Pane } from "shared/components/layouts";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

class MasterLayout extends Component {
  render() {
    const {
      match: { path },
    } = this.props;
    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {})
    );
    const pathLayoutUser = ["/signin", "/signup", "/forgot-password", "/:id/reference","/quick-registration"];
    if (pathLayoutUser.includes(path)) {
      return (
        <Wrapper id="wrapper">
          <LoadingBar className="loading-top-bar" />
          <Header />
          <Pane as="main" className="main-container">
            {childrenWithProps}
          </Pane>
        </Wrapper>
      );
    }
    return (
      <Wrapper id="wrapper">
        <LoadingBar className="loading-top-bar" />
        <Header />
        <Pane as="main" className="main-container">
          {childrenWithProps}
        </Pane>
        <Footer />
      </Wrapper>
    );
  }
}
MasterLayout.propTypes = {};
MasterLayout.defaultProps = {};
export default withRouter(MasterLayout);
