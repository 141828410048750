import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from "react-responsive-modal";
import { Link } from 'react-router-dom';
import { Pane, Loading } from "shared/components/layouts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table, Tab, Dimmer, Icon, Label, Button } from 'semantic-ui-react';
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { getScanCount } from 'actions/scanCounts';
import { getScreenCount } from 'actions/screens';
import { getOrdersBookings, getSellingOrders } from 'actions/orders';
import { getTablesByUserId } from "actions/myTables";
import UserAdapter from "services/users/adapter";
import { Tables } from '../common/tables';
import { isEmpty } from 'lodash';
import { formatDecimal } from 'shared/helpers';
import Config from "constants/Config";
import Pusher from 'pusher-js';
import { formatRoute } from 'shared/helpers';
class Dashboard extends Component {
  static propTypes = {
    getCollectionsByUserId: PropTypes.func,
    getSellingOrders: PropTypes.func,
    intl: intlShape.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this._notificationSystem = null;
    this.subscribeToPusher = this.subscribeToPusher.bind(this);
    this.state = {
      loadingScan: false,
      loadingTable: false,
      loading: false,
      dataSource: [],
      delivery_method: '',
      mode: 'table',
      method: Config.DELIVERY_METHOD.DINE_IN,
      activeIndex: 0,
      open: false,
      openPos: false,
    };
  }

  getScanCount = () => {
    this.setState({
      loadingScan: true
    });
    this.props.getScanCount().then(() => {
      this.setState({
        loadingScan: false
      });
    }).catch(() => {
      this.setState({
        loadingScan: false
      });
    });
  }

  getScreenCount = () => {
    this.setState({
      loadingScan: true
    });
    this.props.getScreenCount().then(() => {

      this.setState({
        loadingScan: false,
      });
    }).catch(() => {
      this.setState({
        loadingScan: false
      });
    });
  }

  getOrdersBookings = (params) => {
    this.setState({
      loading: true,
    });
    this.props
      .getOrdersBookings(params)
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  getListById = (params) => {
    const { userInfo } = this.props;
    this.setState({ loadingTable: true });
    this.props
      .getTablesByUserId({
        ...params,
        business_id: userInfo.business && userInfo.business.id,
      })
      .then((response) => {
        if (response) {
          this.setState({
            dataSource: UserAdapter.tablesBrowse.parseResponse(response.data),
            loadingTable: false,
          });
        }
      })
      .catch(() => {
        this.setState({ loadingTable: false });
      });
  };

  getSellingOrders = (params, load) => {
    this.setState({ loadingTable: true });
    this.props.getSellingOrders(params, load).then((res) => {
      if (res) {
        this.setState({ loadingTable: false });
      }
    }).catch(() => {
      this.setState({ loadingTable: false });
    })
  };

  componentDidMount() {
    const { userInfo } = this.props;

    if (parseInt(userInfo.business.is_active) !== 1) {
      this.props.history.push(`/u/${formatRoute(userInfo.displayName)}/collections`);
    }

    this.getScanCount();
    this.getScreenCount();
    this.initOrder(Config.DELIVERY_METHOD.DINE_IN)

    setTimeout(() => {
      this.subscribeToPusher();
    }, 5000)

    let type = this.props.userInfo.business.type === Config.BUSINESS_TYPE['FOOD'] ? 'ORDER' : 'BOOKING';

    this.getOrdersBookings({ id: this.props.userInfo.id, type: type });

    const elementScroll = document.getElementById("anchor");
    if (elementScroll) {
      setTimeout(() => {
        elementScroll.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }

  subscribeToPusher() {
    let { userInfo } = this.props;
    var pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, {
      encrypted: true,
      cluster: "ap4"
    });

    // Subscribe to the channel
    var channel = pusher.subscribe('my-channel');

    // Bind a function to a Event
    channel.bind('my-event', (data) => {
      if (data.delivery_method) {
        this.setState({ delivery_method: data.delivery_method })
        if (data.delivery_method == Config.DELIVERY_METHOD.DINE_IN || data.delivery_method == Config.DELIVERY_METHOD.TAKE_AWAY) {
          this.getListById({ method: data.delivery_method });
        }
      }

      let type = this.props.userInfo.business.type === Config.BUSINESS_TYPE['FOOD'] ? 'ORDER' : 'BOOKING';
      this.getOrdersBookings({ id: this.props.userInfo.id, type: type });

      if (data && data.target_id && userInfo.id == data.target_id) {
        //   const soundEffect = new Audio();
        // // soundEffect.autoplay = true;
        // soundEffect.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

        // soundEffect.src = '/assets/sounds/noti.mp3';
        let audio = document.getElementsByClassName("audio-element")[0]
        setTimeout(() => {
          let promise = audio.play();
          if (promise !== undefined) {
            promise.then(_ => { }).catch(error => { });
          }
        }, 1000)
        // let audio = new Audio("/assets/sounds/noti.mp3");
        let noti_order_el = document.getElementById("noti-order");
        let noti_new_el = document.getElementById("noti-new");
        if (noti_order_el && noti_new_el) {
          noti_order_el.classList.add("noti-animation");
          noti_new_el.classList.add("d-block");
        }
      }
    })
  }

  initOrder = (value) => {
    if (value == Config.DELIVERY_METHOD.DELIVERY || value == Config.DELIVERY_METHOD.PICKUP) {
      this.setState({ mode: 'list' })
      const params = {
        page: 1,
        id: this.props.userInfo.id,
        method: this.state.method,
        status: Config.ORDER_STATUS.NEW
      };

      this.getSellingOrders(params);
    } else {
      this.setState({ mode: 'table' })
      this.getListById({ method: this.state.method });
    }
  }

  filterDeliveryMethod = (value) => {

    this.setState({
      method: value,
      activeIndex: 2
    }, () => {
      this.initOrder(value);
    })
  }

  handleClickScreenReport = () => {
    this.props.history.push(`/u/${formatRoute(this.props.userInfo.displayName)}/digital-slideshow`);
  }

  onChangeRoute = (url) => {
    this.props.history.push(url);
  }

  handleTabChange = (e, { activeIndex }) => {
    if (activeIndex === 3) {
      if (this.state.dataSource.length > 0) {
        this.props.history.push(`/${formatRoute(this.props.userInfo.displayName)}/pos?type=TABLE`);
      } else {
        this.onOpenModalPOS();
      }
    } else {
      this.setState({ activeIndex })
    }
  }

  onClose = () => {
    this.setState({ open: false });
  }

  onCloseModalPOS = () => {
    this.setState({ openPos: false });
  }

  onOpen = () => {
    this.setState({ open: true });
  }

  onOpenModalPOS = () => {
    this.setState({ openPos: true });
  }

  renderTab = () => {
    const { scanCounts, ordersBookings, userInfo, sellingOrders, screenCount } = this.props;
    const { loadingScan, loading, loadingTable, dataSource, delivery_method, method, mode, activeIndex } = this.state;
    const is_food = userInfo.business.type === Config.BUSINESS_TYPE['FOOD'];
    const name =
      userInfo && userInfo.displayName
        ? userInfo.displayName.replace(/ /g, "_").toLowerCase()
        : "";
    const type = is_food ? `orders` : 'bookings';
    const panes = [
      {
        menuItem: 'QR CODE SCANS',
        render: () =>
          <Tab.Pane attached='top'>
            <Pane className="col-12 p-0">
              <Pane className="card">
                <Pane className="d-flex align-items-center">
                  <Pane className="card-icon blue">
                    <span className="ic-common-qr-code" />
                  </Pane>
                  <h3 className="pb-0">QR Code Scans</h3>
                </Pane>
                {!loadingScan ? (
                  <Pane className="card-statistic">
                    <Pane>
                      <p className="num">{scanCounts.scans}</p>
                      <span className="title">Today's scans</span>
                    </Pane>
                    <Pane>
                      <p className="num">{scanCounts.total_scans}</p>
                      <span className="title">Total scans</span>
                    </Pane>
                  </Pane>
                ) : (
                  <Pane className="dashboard-loading">
                    <Dimmer active inverted>
                      <Loading />
                    </Dimmer>
                  </Pane>
                )}
              </Pane>
            </Pane>
          </Tab.Pane>,
      },
      {
        menuItem: is_food ? 'ORDERS' : 'BOOKINGS',
        render: () =>
          <Tab.Pane attached='top'>
            <Pane className="col-12 p-0">
              <Pane className="card">
                <Pane className="d-flex align-items-center">
                  <Link to={`/u/${name}/${type}`}>
                    <Pane className="position-relative">
                      <i className="icon-bell" id="noti-order"></i>
                      <span className="d-none" id="noti-new">
                        NEW
                      </span>
                    </Pane>
                  </Link>
                  <Pane className="card-icon green">
                    <i className='ic-common-order'></i>
                  </Pane>
                  <h3 className="pb-0">{userInfo.business.type === Config.BUSINESS_TYPE['FOOD'] ? `Orders` : 'Bookings'}</h3>
                </Pane>
                {!loading ? (
                  <Pane className="card-statistic">
                    <Pane>
                      <p className="num" id="num_ord_today">{ordersBookings.results.ordersToday}</p>
                      <span className="title">Today's {type}</span>
                    </Pane>
                    <Pane>
                      <Link to={`/u/${name}/${type}`}>
                        <p className="num" id="num_ord_total">{ordersBookings.results.ordersTotal}</p>
                        <span className="title">Total {type}</span>
                      </Link>
                    </Pane>
                  </Pane>
                ) : (
                  <Pane className="dashboard-loading">
                    <Dimmer active inverted>
                      <Loading />
                    </Dimmer>
                  </Pane>
                )}
                {userInfo.business.type === Config.BUSINESS_TYPE['FOOD'] && (
                  <Pane className="block-status d-flex" onClick={() => this.setState({ delivery_method: '' })}>
                    <Pane onClick={() => this.filterDeliveryMethod('DINE_IN')} className="cursor-pointer mr-3">
                      <i className={`fa fa-circle dot-status ${delivery_method === Config.DELIVERY_METHOD.DINE_IN ? ' text-danger-glow blink' : ''}`}></i>
                      <span> Dine-In </span>
                    </Pane>
                    <Pane onClick={() => this.filterDeliveryMethod('TAKE_AWAY')} className="cursor-pointer mr-3">
                      <i className={`fa fa-circle dot-status ${delivery_method === Config.DELIVERY_METHOD.TAKE_AWAY ? ' text-danger-glow blink' : ''}`}></i>
                      <span> Take Away </span>
                    </Pane>
                    <Pane onClick={() => this.filterDeliveryMethod('DELIVERY')} className="cursor-pointer mr-3">
                      <i className={`fa fa-circle dot-status ${delivery_method === Config.DELIVERY_METHOD.DELIVERY ? ' text-danger-glow blink' : ''}`}></i>
                      <span> Delivery </span>
                    </Pane>
                    <Pane onClick={() => this.filterDeliveryMethod('PICKUP')} className="cursor-pointer">
                      <i className={`fa fa-circle dot-status ${delivery_method === Config.DELIVERY_METHOD.PICKUP ? ' text-danger-glow blink' : ''}`}></i>
                      <span> Pick Up </span>
                    </Pane>
                  </Pane>
                )}
              </Pane>
            </Pane>
            {is_food && (
              <Pane className="col-12 p-0">
                <Pane className="card card-2">
                  {!loading ? (
                    <Pane className="card-statistic">
                      <Pane>
                        <span className="title">Total Revenue</span>
                        <p className="num" id="num_ord_today">AUD ${formatDecimal(ordersBookings.results.data.totalPrice)}</p>
                      </Pane>
                      <Pane onClick={this.onOpen} className="cursor-pointer">
                        <span className="title">Products Sold</span>
                        <p className="num" id="num_ord_total">{ordersBookings.results.data.products.length}</p>
                      </Pane>
                    </Pane>
                  ) : (
                    <Pane className="dashboard-loading">
                      <Dimmer active inverted>
                        <Loading />
                      </Dimmer>
                    </Pane>
                  )}

                </Pane>
              </Pane>
            )}
          </Tab.Pane>,
      },
      {
        menuItem: 'DIGITAL SLIDESHOW',
        render: () =>
          <Tab.Pane attached='top'>
            <Pane className="col-12 p-0 screen">
              <Pane className="card">
                <Pane className="d-flex align-items-center">
                  <Pane className="card-icon blue">
                    <span className="ic-common-video-player" />
                  </Pane>
                  <h3 className="pb-0">Digital Slideshow</h3>
                </Pane>
                {!loadingScan ? (
                  <Pane className="card-statistic">
                    <Pane>
                      <p className="num">{screenCount.results.total_screen}</p>
                      <span className="title link" onClick={() => this.handleClickScreenReport()}>Total screen <i className="fas fa-external-link-alt"></i></span>
                    </Pane>
                    <Pane>
                      <p className="num">{screenCount.results.share_active}</p>
                      <span className="title link" onClick={() => this.handleClickScreenReport()} >Request Share Active <i className="fas fa-external-link-alt"></i></span>
                    </Pane>
                    <Pane>
                      <p className="num">{screenCount.results.share_pending}</p>
                      <span className="title link" onClick={() => this.handleClickScreenReport()}>Request Share Pending <i className="fas fa-external-link-alt"></i></span>
                    </Pane>
                  </Pane>
                ) : (
                  <Pane className="dashboard-loading">
                    <Dimmer active inverted>
                      <Loading />
                    </Dimmer>
                  </Pane>
                )}
              </Pane>
            </Pane>
          </Tab.Pane>,
      },
    ];
    if (is_food) {
      panes.splice(3, 0, {
        menuItem: 'TABLE ORDER',
      });
      panes.splice(4, 0,
        {
          menuItem: method,
          render: () => <Tab.Pane attached='top'>
            {userInfo.business.type === Config.BUSINESS_TYPE['FOOD'] && (
              <Pane className="col-12 p-0">
                <Pane className="comment-container mb-3">
                  <Pane className={`tables col-12 ${mode === 'list' ? 'list' : ''}`}>
                    {!loadingTable ? (
                      <>
                        {mode === 'table' ? (
                          <Link to={`/u/${name}/orders?delivery_type=${method}`}>
                            <Tables tables={dataSource} />
                          </Link>
                        ) : (
                          <Pane>
                            <Table>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    <FormattedMessage
                                      id="customer_name"
                                      defaultMessage="Customer name"
                                    />
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    <FormattedMessage
                                      id="total_payment"
                                      defaultMessage="Total payment"
                                    />
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    <FormattedMessage
                                      id="payment_method"
                                      defaultMessage="Payment method"
                                    />
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>

                              <Table.Body>{sellingOrders && sellingOrders.results.map((item) => {
                                return (
                                  <Table.Row key={item.id} className="cursor-pointer" onClick={() => this.onChangeRoute(`/u/${name}/orders/${item.id}/selling/detail`)}>
                                    <Table.Cell>{item.mode !== "GUEST" ? (item.customer ? item.customer.name : '') || '' : item.guestName}</Table.Cell>
                                    <Table.Cell>AUD ${formatDecimal(item.total)}</Table.Cell>
                                    <Table.Cell>{item.paymentMethod}</Table.Cell>
                                    <Table.Cell>
                                      <Icon name="eye" />
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              })}</Table.Body>
                            </Table>
                            {!loadingTable &&
                              isEmpty(sellingOrders.results) && (
                                <Pane className="block-no-content">
                                  <p>
                                    <FormattedMessage
                                      id="notification.you_have_not_had_any_orders_yet"
                                      defaultMessage={`You haven't had any orders yet.`}
                                    />
                                  </p>
                                </Pane>
                              )}
                          </Pane>
                        )}
                      </>
                    ) : (
                      <Pane className="dashboard-loading">
                        <Dimmer active inverted>
                          <Loading />
                        </Dimmer>
                      </Pane>
                    )}
                  </Pane>
                </Pane>
              </Pane>
            )}
          </Tab.Pane>,
        },
      )

    }
    return <Tab activeIndex={activeIndex} onTabChange={this.handleTabChange} menu={{ secondary: true, pointing: true }} panes={panes} />;
  };

  render() {
    const { ordersBookings, userInfo } = this.props;
    const { loadingTable } = this.state;
    const name =
      userInfo && userInfo.displayName
        ? userInfo.displayName.replace(/ /g, "_").toLowerCase()
        : "";
    return (
      <Pane className="dashboard row" id="anchor">
        <Modal
          classNames={{
            modal: 'popup-ana',
          }}
          open={this.state.openPos}
          center
          showCloseIcon={true}
          onClose={this.onCloseModalPOS}
        >
          <Pane className="modal-pos">
            <Pane className="modal-pos__label">Please create store's table before using Table Order System</Pane>
            <Link to={`/u/${name}/tables`}><em>Create table now!</em></Link>
          </Pane>
        </Modal>
        <Modal
          classNames={{
            modal: 'popup-ana',
          }}
          open={this.state.open}
          center
          showCloseIcon={true}
          onClose={this.onClose}
        >
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell><Label ribbon>Product</Label></Table.HeaderCell>
                <Table.HeaderCell>Cost per item</Table.HeaderCell>
                <Table.HeaderCell>Items Sold</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {!this.state.loading && (
                <>
                  {ordersBookings.results.data && ordersBookings.results.data.products.length > 0 && ordersBookings.results.data.products.map((item) => {
                    return (
                      <Table.Row key={item.product_id}>
                        <Table.Cell>
                          <Link to={`/u/${name}/menu/${item.productId}/edit`}>{item.name}</Link>
                        </Table.Cell>
                        <Table.Cell>AUD ${formatDecimal(item.price)}</Table.Cell>
                        <Table.Cell>{item.sumQuantity}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </>
              )}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='3'>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Modal>
        <Pane className="col-12 p-0">
          {!loadingTable ? (
            this.renderTab()
          ) : (
            <Pane className="dashboard-loading">
              <Dimmer active inverted>
                <Loading />
              </Dimmer>
            </Pane>
          )}
        </Pane>
        <audio className="audio-element" playsInline >
          <source src={`/assets/sounds/noti.mp3?v=${Math.random()}`} preload="none" />
        </audio>
      </Pane>
    );
  }
}


const bindStateToProps = (state) => ({
  userInfo: state.auth.user,
  scanCounts: state.scanCounts.results,
  ordersBookings: state.ordersBookings,
  sellingOrders: state.sellingOrders,
  screenCount: state.screenCount
});

const bindDispatchToProps = (dispatch) => ({
  getScanCount: bindActionCreators(getScanCount, dispatch),
  getOrdersBookings: bindActionCreators(getOrdersBookings, dispatch),
  getTablesByUserId: bindActionCreators(getTablesByUserId, dispatch),
  getSellingOrders: bindActionCreators(getSellingOrders, dispatch),
  getScreenCount: bindActionCreators(getScreenCount, dispatch),

});

export default connect(
  bindStateToProps,
  bindDispatchToProps
)(injectIntl(Dashboard));